$border: #bcbcbc99;

.profile{
    .profileContainer{
        margin: 0 5%;
        .profileWrapper{
            display: flex;
            padding: 10px 0;
            .profNav{
                width: 250px;
                border: 1px solid #000;
                border-radius: 5px;
                margin-right: 20px;
                .profunlist{
                    .profNavLinkActive{
                        background-color: #00000068;
                    }
                    .profNavLink{
                        padding: 15px 20px;
                        margin: 5px 0;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        &:nth-child(1){
                            margin: 0;
                        }
                        &:hover{
                            background-color: #0000002a;
                        }
                        .profIcNav{
                            height: 20px;
                            width: 20px;
                            fill: #000;
                            margin-right: 10px;
                        }
                        .profNavLinkCont{
                            font-size: 1.1rem;
                            white-space: nowrap !important;
                        }
                    }
                }
            }
            .profPlayarea{
                flex: 1;
                .accoverview{
                    width: 100%;
                    background-color: #ffffff78;
                    border-radius: 5px;
                    height: 600px;
                    .accrevtop{
                        padding: 12px 10px;
                        padding-left: 40px;
                        border-bottom: 1px solid $border;
                        h1{
                            font-size: 1.5rem;
                        }
                    }
                    .accrevCont{
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        justify-content: space-evenly;
                        .accovdetcont{
                            border: 1px solid $border;
                            border-radius: 5px;
                            width: 45%;
                            margin: 20px 0;
                            .acovtopdet{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding: 10px;
                                border-bottom: 1px solid $border;
                                h2{
                                    font-size: 1rem;
                                }
                                .profaccovIcNav{
                                    height: 10px;
                                    width: 10px;
                                    fill: #61c5ff;
                                    margin-right: 10px;
                                }
                            }
                            .accovdetcontdet{
                                padding: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}