.header {
  height: 70px;
  position: relative;
  background-color: #242424;
  transition: 0.3s;
  display: flex;
  align-items: center;
}
@media (max-width: 830px) {
  .header {
    height: 110px !important;
  }
}
.header .hcontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5%;
  width: 90%;
}
@media (max-width: 830px) {
  .header .hcontainer {
    flex-wrap: wrap;
  }
}
.header .hcontainer .left {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 25%;
}
@media (max-width: 830px) {
  .header .hcontainer .left {
    order: 1;
    width: 50%;
  }
}
.header .hcontainer .left .logoname {
  margin-right: 20px;
  color: #fff;
  font-family: "Itim", cursive;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  white-space: nowrap;
}
.header .hcontainer .left .logoname .mobibars,
.header .hcontainer .left .logoname .mobixmark {
  height: 25px;
  height: 25px;
  fill: #fff;
  margin-right: 10px;
  cursor: pointer;
}
@media (min-width: 830px) {
  .header .hcontainer .left .logoname .mobibars,
  .header .hcontainer .left .logoname .mobixmark {
    display: none;
  }
}
.header .hcontainer .left .logoname .logonamelink {
  text-decoration: none;
  color: #fff;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.header .hcontainer .left .logoname .logonamelink h2 {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
@media (max-width: 430px) {
  .header .hcontainer .left .logoname .logonamelink h2 {
    display: none;
  }
}
.header .hcontainer .left .logoname .logonamelink .nullchemy_logo_mobile {
  height: 25px;
  width: 25px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
@media (min-width: 430px) {
  .header .hcontainer .left .logoname .logonamelink .nullchemy_logo_mobile {
    display: none;
  }
}
@media (max-width: 830px) {
  .header .hcontainer .left .filtercategories {
    display: none !important;
  }
}
@media (min-width: 830px) {
  .header .hcontainer .left .mobilesidebarnav {
    display: none !important;
  }
}
.header .hcontainer .middle {
  width: 45%;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1439px) {
  .header .hcontainer .middle {
    width: 35%;
  }
}
@media (max-width: 1250px) {
  .header .hcontainer .middle {
    width: 40%;
  }
}
@media (max-width: 830px) {
  .header .hcontainer .middle {
    order: 3;
    width: 100%;
  }
}
.header .hcontainer .right {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
@media (max-width: 1439px) {
  .header .hcontainer .right {
    width: 25%;
  }
}
@media (max-width: 1250px) {
  .header .hcontainer .right {
    width: 25%;
  }
}
@media (max-width: 830px) {
  .header .hcontainer .right {
    order: 2;
    width: 50%;
    justify-content: flex-end;
  }
}
.header .hcontainer .right span {
  margin-right: 20px;
  font-size: 0.8rem;
  font-family: Roboto;
  cursor: pointer;
  color: #fff;
  white-space: nowrap;
}
@media (max-width: 830px) {
  .header .hcontainer .right span {
    display: none;
  }
}
.header .hcontainer .right .login .accountdropdown,
.header .hcontainer .right .headercart .accountdropdown {
  position: relative;
}
.header .hcontainer .right .login .accountdropdown .linktologinusr,
.header .hcontainer .right .headercart .accountdropdown .linktologinusr {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
  text-decoration: none;
}
.header .hcontainer .right .login .accountdropdown .linktologinusr .headeruser,
.header .hcontainer .right .headercart .accountdropdown .linktologinusr .headeruser {
  height: 15px;
  width: 15px;
  fill: #fff;
}
.header .hcontainer .right .login .accountdropdown .linktologinusr span,
.header .hcontainer .right .headercart .accountdropdown .linktologinusr span {
  margin: 0 5px;
  -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
  -moz-user-select: none;
       user-select: none;
}
.header .hcontainer .right .login .accountdropdown .linktologinusr .angleusrdownaccount,
.header .hcontainer .right .headercart .accountdropdown .linktologinusr .angleusrdownaccount {
  height: 10px;
  width: 10px;
  fill: #fff;
}
@media (max-width: 830px) {
  .header .hcontainer .right .login .accountdropdown .linktologinusr .angleusrdownaccount,
  .header .hcontainer .right .headercart .accountdropdown .linktologinusr .angleusrdownaccount {
    display: none;
  }
}
.header .hcontainer .right .login .accountdropdown .acctdropdown,
.header .hcontainer .right .headercart .accountdropdown .acctdropdown {
  background-color: #5b5b5b;
  position: absolute;
  z-index: 998;
  margin-top: 22px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
}
.header .hcontainer .right .login .accountdropdown .acctdropdown::before,
.header .hcontainer .right .headercart .accountdropdown .acctdropdown::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #5b5b5b;
  position: absolute;
  top: -10px;
  left: 30px;
  z-index: 900;
}
@media (max-width: 830px) {
  .header .hcontainer .right .login .accountdropdown .acctdropdown,
  .header .hcontainer .right .headercart .accountdropdown .acctdropdown {
    width: 100px;
    right: 0;
  }
  .header .hcontainer .right .login .accountdropdown .acctdropdown::before,
  .header .hcontainer .right .headercart .accountdropdown .acctdropdown::before {
    left: unset;
    right: 15px;
  }
}
.header .hcontainer .right .login .accountdropdown .acctdropdown .linktologin,
.header .hcontainer .right .headercart .accountdropdown .acctdropdown .linktologin {
  padding: 10px 15px;
  margin: 0 !important;
}
.header .hcontainer .right .login .accountdropdown .acctdropdown .linktologin:hover,
.header .hcontainer .right .headercart .accountdropdown .acctdropdown .linktologin:hover {
  background-color: #757575;
}
.header .hcontainer .right .login .accountdropdown .acctdropdown .linktologin P,
.header .hcontainer .right .headercart .accountdropdown .acctdropdown .linktologin P {
  color: #fff;
  margin-left: 5px;
  font-size: 0.9rem;
}
.header .hcontainer .right .login .accountdropdown .acctdropdown .linktologin .usr_drop_ic,
.header .hcontainer .right .headercart .accountdropdown .acctdropdown .linktologin .usr_drop_ic {
  height: 15px;
  width: 15px;
}
.header .hcontainer .right .login .linktologin,
.header .hcontainer .right .login .linktocart,
.header .hcontainer .right .headercart .linktologin,
.header .hcontainer .right .headercart .linktocart {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
  text-decoration: none;
}
.header .hcontainer .right .login .linktologin span,
.header .hcontainer .right .login .linktocart span,
.header .hcontainer .right .headercart .linktologin span,
.header .hcontainer .right .headercart .linktocart span {
  margin-left: 10px;
  font-family: Roboto;
  color: #fff;
  font-size: 0.8rem;
}
@media (max-width: 830px) {
  .header .hcontainer .right .login .linktologin span,
  .header .hcontainer .right .login .linktocart span,
  .header .hcontainer .right .headercart .linktologin span,
  .header .hcontainer .right .headercart .linktocart span {
    display: none;
  }
}
.header .hcontainer .right .login .linktologin .headeruser,
.header .hcontainer .right .login .linktologin .headcart,
.header .hcontainer .right .login .linktocart .headeruser,
.header .hcontainer .right .login .linktocart .headcart,
.header .hcontainer .right .headercart .linktologin .headeruser,
.header .hcontainer .right .headercart .linktologin .headcart,
.header .hcontainer .right .headercart .linktocart .headeruser,
.header .hcontainer .right .headercart .linktocart .headcart {
  height: 25px;
  width: 25px;
  fill: #fff;
}
.header .hcontainer .right .login .minicartdropdown .linktocart,
.header .hcontainer .right .headercart .minicartdropdown .linktocart {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
  text-decoration: none;
  position: relative;
}
.header .hcontainer .right .login .minicartdropdown .linktocart .cartQuantityCounter,
.header .hcontainer .right .headercart .minicartdropdown .linktocart .cartQuantityCounter {
  align-items: center;
  background-color: #cc1016;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 10px;
  height: 5px;
  justify-content: center;
  margin-right: -5px;
  margin-top: -5px;
  padding: 6px;
  position: absolute;
  left: 5px;
  text-align: center;
  top: 0;
  width: 5px;
  z-index: 998;
}
.header .hcontainer .right .login .minicartdropdown .linktocart span,
.header .hcontainer .right .headercart .minicartdropdown .linktocart span {
  margin-left: 10px;
  font-family: Roboto;
  color: #fff;
  font-size: 0.8rem;
}
@media (max-width: 830px) {
  .header .hcontainer .right .login .minicartdropdown .linktocart span,
  .header .hcontainer .right .headercart .minicartdropdown .linktocart span {
    display: none;
  }
}
.header .hcontainer .right .login .minicartdropdown .linktocart .headcart,
.header .hcontainer .right .headercart .minicartdropdown .linktocart .headcart {
  height: 25px;
  width: 25px;
  fill: #fff;
}

.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.15);
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  transition: 0.4s top cubic-bezier(0.3, 0.73, 0.3, 0.74);
  padding-top: 0px;
  padding-bottom: 0px;
}

@keyframes fadeInDown {
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
}/*# sourceMappingURL=header.css.map */