.allproducts .allproductscontainer {
  margin: 0 5%;
}
@media (max-width: 480px) {
  .allproducts .allproductscontainer {
    margin: 0 1%;
  }
}
.allproducts .allproductscontainer .allproductsinfobox {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  border: 1px solid #00ff55;
  background-color: #009532;
  padding: 5px 10px;
  border-radius: 20px;
  color: #fff;
}
.allproducts .allproductscontainer .allproductserrbox {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  border: 1px solid #ff0000;
  background-color: #950000;
  padding: 5px 10px;
  border-radius: 20px;
  color: #fff;
}
.allproducts .allproductscontainer .allproductswrapper {
  margin: 20px 0;
}
@media (max-width: 480px) {
  .allproducts .allproductscontainer .allproductswrapper {
    margin-top: 0px;
  }
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout {
  display: flex;
  gap: 2rem;
}
@media (max-width: 480px) {
  .allproducts .allproductscontainer .allproductswrapper .allproductslayout {
    flex-direction: column;
  }
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsleftSticky {
  position: sticky;
  top: 80px;
  transition: 0.3s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsleft {
  width: 15%;
  padding: 10px;
  border-radius: 5px;
  height: -moz-min-content;
  height: min-content;
}
@media (max-width: 480px) {
  .allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsleft {
    display: none;
  }
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsleft h1 {
  margin-left: 10px;
  font-size: 1.1rem;
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsleft .allprodsec {
  border-bottom: 1px solid #b3b3b3;
  padding: 10px;
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsleft .allprodsec:nth-last-child(1) {
  border-bottom: none;
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsleft .allprodsec h2 {
  text-transform: uppercase;
  font-size: 1rem;
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsleft .allprodsec .allprodcatlist .allprodcatlistfieldset {
  outline: none;
  border: none;
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsleft .allprodsec .allprodcatlist .allprodcatlistfieldset .all_prod_list_selected_products {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsleft .allprodsec .allprodcatlist .allprodcatlistfieldset .all_prod_list_selected_products .all_prod_list_item {
  font-size: 0.7rem;
  font-weight: 400;
  white-space: nowrap;
  margin-right: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  padding: 3px 7px;
  position: relative;
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsleft .allprodsec .allprodcatlist .allprodcatlistfieldset .all_prod_list_selected_products .all_prod_list_item::before {
  background: linear-gradient(45deg, #743ad5, #d53a9d);
  border-radius: 50px;
  content: "";
  inset: 0;
  -webkit-mask: linear-gradient(var(--text-color) 0 0) content-box, linear-gradient(var(--text-color) 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  padding: 2px;
  position: absolute;
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsleft .allprodsec .allprodcatlist .allprodcatlistfieldset .allprodlistitem {
  padding: 5px 20px;
  color: #000;
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsleft .allprodsec .allprodcatlist .allprodcatlistfieldset .allprodlistitem .filterradioselection {
  margin-right: 10px;
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsleft .allprodsec .allprodcatlist .allprodcatlistfieldset .allprodlistitem:hover {
  color: #50a2ff;
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsleft .allprodsec .allprodcatlist .allprodcatlistfieldset .allprodlistitem a {
  text-decoration: none;
  color: inherit;
  white-space: nowrap;
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsleft .allprodsec .allprodcatlist .allprodlistitem {
  padding: 5px 20px;
  color: #000;
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsleft .allprodsec .allprodcatlist .allprodlistitem input {
  margin-right: 10px;
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsleft .allprodsec .allprodcatlist .allprodlistitem:hover {
  color: #50a2ff;
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsleft .allprodsec .allprodcatlist .allprodlistitem a {
  text-decoration: none;
  color: inherit;
  white-space: nowrap;
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsbrowse {
  padding: 10px;
  width: calc(85% - 2.8rem);
  overflow: hidden;
}
@media (max-width: 480px) {
  .allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsbrowse {
    width: calc(100% - 20px);
  }
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsbrowse h1 {
  margin-bottom: 20px;
}
@media (max-width: 480px) {
  .allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsbrowse h1 {
    display: none;
  }
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsbrowse .allproductsbrowsemobilefilters {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 50px;
  width: 100vw;
  background-color: #fff;
  z-index: 999;
}
@media (min-width: 480px) {
  .allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsbrowse .allproductsbrowsemobilefilters {
    display: none;
  }
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsbrowse .allprodshowcase .all_prods_no_results .all_prods_no_results_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsbrowse .allprodshowcase .all_prods_no_results .all_prods_no_results_wrapper .all_prods_no_results_box_center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsbrowse .allprodshowcase .all_prods_no_results .all_prods_no_results_wrapper .all_prods_no_results_box_center .no_results_ic {
  height: 300px;
  margin-bottom: 2rem;
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsbrowse .navigationpagesallprod {
  display: flex;
  align-items: center;
  justify-content: center;
}
.allproducts .allproductscontainer .allproductswrapper .allproductslayout .allproductsbrowse .navigationpagesallprod .navbuttonallprod {
  padding: 5px 10px;
  border: 1px solid #000;
  margin: 5px;
  cursor: pointer;
}

.allprodshowcase {
  margin: 0px !important;
}/*# sourceMappingURL=allproducts.css.map */