.product_review .prod_write_a_review {
  padding-top: 2rem;
}
.product_review .prod_write_a_review .prod_write_review_wrapper .prod_write_review_form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}
.product_review .prod_write_a_review .prod_write_review_wrapper .prod_write_review_form .write_prod_rev_form_group {
  width: 100%;
  margin-bottom: 1rem;
}
.product_review .prod_write_a_review .prod_write_review_wrapper .prod_write_review_form .write_prod_rev_form_group label {
  font-size: 1rem;
}
.product_review .prod_write_a_review .prod_write_review_wrapper .prod_write_review_form .write_prod_rev_form_group label span {
  color: red;
}
.product_review .prod_write_a_review .prod_write_review_wrapper .prod_write_review_form .write_prod_rev_form_group .review_prod_input {
  padding: 5px;
  width: calc(100% - 5px);
  max-width: calc(100% - 5px);
  min-width: calc(100% - 5px);
  min-height: 200px;
  height: 200px;
  max-height: 300px;
  border-radius: 5px;
  border: 1px solid #000;
  outline: none;
  margin-top: 10px;
  font-size: 1rem;
}
.product_review .prod_write_a_review .prod_write_review_wrapper .prod_write_review_form .prod_post_review_btn {
  all: unset;
  width: -moz-max-content;
  width: max-content;
  padding: 10px 30px;
  background-color: #fff;
  border-radius: 43px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
  background: linear-gradient(to bottom right, #9651ff, #5c00e6) !important;
  margin-bottom: 1rem;
}
.product_review .prod_previews_title {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.product_review .product_reviews_container .prod_review_item {
  margin-bottom: 2rem;
}
.product_review .product_reviews_container .prod_review_item .prod_review_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product_review .product_reviews_container .prod_review_item .prod_review_header .prod_review_header_left {
  flex: 1;
}
.product_review .product_reviews_container .prod_review_item .prod_review_header .prod_review_header_left .prod_reviewer_profile {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.product_review .product_reviews_container .prod_review_item .prod_review_header .prod_review_header_left .prod_reviewer_profile .prod_reviewer_image {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  overflow: hidden;
}
.product_review .product_reviews_container .prod_review_item .prod_review_header .prod_review_header_left .prod_reviewer_profile .prod_reviewer_name {
  font-size: 1rem;
}
.product_review .product_reviews_container .prod_review_item .prod_review_header .prod_review_header_left .review_prod_meta {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.product_review .product_reviews_container .prod_review_item .prod_review_header .prod_review_header_left .review_prod_meta .review_prod_date {
  font-size: 0.8rem;
}
.product_review .product_reviews_container .prod_review_item .prod_review_header .prod_review_header_right {
  position: relative;
}
.product_review .product_reviews_container .prod_review_item .prod_review_header .prod_review_header_right .ellipsis_vertical_review_more {
  height: 20px;
  width: 20px;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  padding: 10px;
}
.product_review .product_reviews_container .prod_review_item .prod_review_header .prod_review_header_right .ellipsis_vertical_review_more:hover {
  background-color: #e6e6e6;
}
.product_review .product_reviews_container .prod_review_item .prod_review_header .prod_review_header_right .prod_review_more_options {
  position: absolute;
  top: 30px;
  right: 20px;
  width: -moz-min-content;
  width: min-content;
  height: -moz-min-content;
  height: min-content;
  border-radius: 5px;
  background-color: #fff;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}
.product_review .product_reviews_container .prod_review_item .prod_review_header .prod_review_header_right .prod_review_more_options .prod_review_more_option_item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}
.product_review .product_reviews_container .prod_review_item .prod_review_header .prod_review_header_right .prod_review_more_options .prod_review_more_option_item:hover {
  background-color: #e2f1ff;
}
.product_review .product_reviews_container .prod_review_item .prod_review_header .prod_review_header_right .prod_review_more_options .prod_review_more_option_item .prod_review_flag {
  height: 20px;
  width: 20px;
}
.product_review .product_reviews_container .prod_review_item .prod_review_header .prod_review_header_right .prod_review_more_options .prod_review_more_option_item span {
  white-space: nowrap;
  font-size: 0.9rem;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.product_review .product_reviews_container .prod_review_item .prod_review_body .prod_preview_text {
  font-size: 1rem;
  margin: 1rem 0 0.6rem 0;
}
.product_review .product_reviews_container .prod_review_item .prod_review_body .prod_review_is_helpful {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  margin: 0.6rem 0;
}
.product_review .product_reviews_container .prod_review_item .prod_review_body .prod_review_is_helpful .review_helpful_choices .review_helpful_choice {
  padding: 3px 6px;
  border-radius: 23px;
  border: 1px solid #000;
  margin-left: 1rem;
  font-size: 0.7rem;
  cursor: pointer;
}
.product_review .product_reviews_container .prod_review_item .prod_review_body .prod_review_is_helpful .review_helpful_choices .review_helpful_choice:hover {
  background-color: #e4e4e4;
}/*# sourceMappingURL=productreviews.css.map */