.paymentsuccess{
    height: calc(100vh - 70px);
    .psucccontainer{
        margin: 0 5%;
        height: 100%;
        .psucclayout{
            display: flex;
            justify-content: space-around;
            height: 100%;
            .psuccleft{
                width: 40%;
                height: 100%;
                position: relative;
                .psuccinfo{
                    position: absolute;
                    top: 40%;
                    transform: translateY(-40%);
                    margin-left: 20%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    .psucctptexts{
                        h1{
                            font-size: 3rem;
                        }
                        p{
                            font-size: 1.3rem;
                        }
                    }
                    .psuccprodprogress{
                        display : flex;
                        justify-content: space-between;
                        align-items : center;
                        position: relative;
                        margin: 50px 0;
                        min-width: 450px;
                        span{
                            position: relative;
                            width: 100%;
                            &::before{
                                content: attr(data);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 24px;
                                height: 24px;
                                background: #4cff85;
                                border-radius: 50%;
                                white-space: pre;
                                text-align: center;
                                font-weight: 600;
                                position: absolute;
                                top: 0;
                                z-index: 2;
                            }
                            &::after{
                                content: "";
                                display: block;
                                height: 4px;
                                background: #3bb862;
                                width: 100%;
                                position: absolute;
                                top: 11px;
                                z-index: 1;
                            }
                            &:nth-last-child(1){
                                &::after{
                                    display: none !important;
                                }
                            }
                            P{
                                position: absolute;
                                top: 25px;
                                white-space: nowrap;
                                font-size: .8rem;
                                margin-left: 5px;
                            }
                        }
                    }
                    .psuccbackhome {
                        z-index: 1;
                        overflow: hidden !important;
                        background: transparent;
                        position: relative;
                        padding: 8px 50px;
                        border-radius: 30px;
                        cursor: pointer;
                        font-size: 1em;
                        letter-spacing: 2px;
                        transition: 0.2s ease;
                        font-weight: bold;
                        margin: 20px 0px;
                        text-transform: uppercase;
                        width: 60px;
                        &.psuccbackgreen {
                            border: 4px solid var(--green);
                            color: var(--blue);
                            &:before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 0%;
                            height: 100%;
                            background: var(--green);
                            z-index: -1;
                            transition: 0.2s ease;
                            border-radius: 30px;
                            }
                            &:hover {
                            color: var(--white);
                            background: var(--green);
                            transition: 0.2s ease;
                            &:before {
                                width: 100%;
                            }
                            }
                        }
                    }
                }
            }
            .psuccright{

            }
            .psuccright{
                width: 50%;
                height: 100%;
                position: relative;
                .psuccimgwrapper{
                    width: 70%;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}