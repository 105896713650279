:root {
  --swiper-navigation-color: #27ff0a;
}

.categoriesSec {
  height: max-content !important;
  .catcont {
    margin: 0 5%;
    .catSwiper {
      margin-bottom: 20px;
      .catswipeslide {
        .catproperties {
          // width: 260px;
          // height: 200px;
          background-color: #c2c2ff;
          padding: 5px;
          cursor: pointer;
          @media (max-width: 480px) {
            width: calc(100% - 10px);
          }
          .catpropImagecont {
            width: 100%;
            height: 170px;
            overflow: hidden !important;
            .catsliderImagePreview {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          p {
            font-family: Roboto;
            text-align: center;
            margin-top: 5px;
          }
        }
      }
    }
  }
}

.swiper-button-next {
  background-image: url(../../assets/svg/arrow-right.svg) !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  border: 5px solid #fff;
}
.swiper-button-prev {
  background-image: url(../../assets/svg/arrow-left.svg) !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  border: 5px solid #fff;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  display: none !important;
}
