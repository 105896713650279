.allproducts {
  .allproductscontainer {
    margin: 0 5%;
    @media (max-width: 480px) {
      margin: 0 1%;
    }
    .allproductsinfobox {
      position: fixed;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1000;
      border: 1px solid #00ff55;
      background-color: #009532;
      padding: 5px 10px;
      border-radius: 20px;
      color: #fff;
    }
    .allproductserrbox {
      position: fixed;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1000;
      border: 1px solid #ff0000;
      background-color: #950000;
      padding: 5px 10px;
      border-radius: 20px;
      color: #fff;
    }
    .allproductswrapper {
      margin: 20px 0;
      @media (max-width: 480px) {
        margin-top: 0px;
      }
      .allproductslayout {
        display: flex;
        gap: 2rem;
        @media (max-width: 480px) {
          flex-direction: column;
        }
        .allproductsleftSticky {
          position: sticky;
          top: 80px;
          transition: 0.3s;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        }
        .allproductsleft {
          width: 15%;
          padding: 10px;
          border-radius: 5px;
          height: min-content;
          @media (max-width: 480px) {
            display: none;
          }
          h1 {
            margin-left: 10px;
            font-size: 1.1rem;
          }
          .allprodsec {
            border-bottom: 1px solid #b3b3b3;
            padding: 10px;
            &:nth-last-child(1) {
              border-bottom: none;
            }
            h2 {
              text-transform: uppercase;
              font-size: 1rem;
            }
            .allprodcatlist {
              // margin-left: 10px;
              .allprodcatlistfieldset {
                outline: none;
                border: none;
                .all_prod_list_selected_products {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  margin-top: 5px;
                  .all_prod_list_item {
                    font-size: 0.7rem;
                    font-weight: 400;
                    white-space: nowrap;
                    margin-right: 10px;
                    margin-bottom: 5px;
                    cursor: pointer;
                    padding: 3px 7px;
                    position: relative;
                    &::before {
                      background: linear-gradient(45deg, #743ad5, #d53a9d);
                      border-radius: 50px;
                      content: '';
                      inset: 0;
                      -webkit-mask: linear-gradient(var(--text-color) 0 0)
                          content-box,
                        linear-gradient(var(--text-color) 0 0);
                      -webkit-mask-composite: xor;
                      mask-composite: exclude;
                      padding: 2px;
                      position: absolute;
                    }
                  }
                }
                .allprodlistitem {
                  padding: 5px 20px;
                  color: #000;
                  .filterradioselection {
                    margin-right: 10px;
                  }
                  &:hover {
                    color: #50a2ff;
                  }
                  a {
                    text-decoration: none;
                    color: inherit;
                    white-space: nowrap;
                  }
                }
              }
              .allprodlistitem {
                padding: 5px 20px;
                color: #000;
                input {
                  margin-right: 10px;
                }
                &:hover {
                  color: #50a2ff;
                }
                a {
                  text-decoration: none;
                  color: inherit;
                  white-space: nowrap;
                }
              }
            }
          }
        }
        .allproductsbrowse {
          padding: 10px;
          width: calc(85% - 2.8rem);
          overflow: hidden;
          @media (max-width: 480px) {
            width: calc(100% - 20px);
          }

          h1 {
            margin-bottom: 20px;

            @media (max-width: 480px) {
              display: none;
            }
          }
          .allproductsbrowsemobilefilters {
            position: fixed;
            bottom: 0;
            left: 0;
            height: 50px;
            width: 100vw;
            background-color: #fff;
            z-index: 999;
            @media (min-width: 480px) {
              display: none;
            }
          }
          .allprodshowcase {
            .all_prods_no_results {
              .all_prods_no_results_wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 400px;
                .all_prods_no_results_box_center {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  // justify-content: center;
                  .no_results_ic {
                    height: 300px;
                    margin-bottom: 2rem;
                  }
                }
              }
            }
          }
          .navigationpagesallprod {
            display: flex;
            align-items: center;
            justify-content: center;
            .navbuttonallprod {
              padding: 5px 10px;
              border: 1px solid #000;
              margin: 5px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
.allprodshowcase {
  margin: 0px !important;
}
