.searchbar {
  flex: 1;
}
@media (max-width: 1240px) {
  .searchbar {
    flex: unset;
  }
}
@media (max-width: 830px) {
  .searchbar {
    flex: 1;
  }
}
.searchbar .searchInput {
  background: #fff;
  width: 100%;
  border-radius: 25px;
  position: relative;
  z-index: 998;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
}
@media (max-width: 830px) {
  .searchbar .searchInput {
    margin-top: 10px;
  }
}
.searchbar .searchInput input {
  height: 45px;
  width: calc(100% - 80px);
  outline: none;
  border: none;
  border-radius: 25px;
  padding: 0 60px 0 20px;
  font-size: 18px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 0px;
}
@media (max-width: 1020px) {
  .searchbar .searchInput input {
    width: 0;
    padding: 0 27px 0 20px;
  }
}
@media (max-width: 830px) {
  .searchbar .searchInput input {
    width: calc(100% - 80px);
    padding: 0 60px 0 20px;
  }
}
.searchbar .searchInput .SearchresultBox {
  padding: 0;
  opacity: 0;
  pointer-events: none;
  max-height: 280px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 50px;
  position: absolute;
  background: #ffffff;
  width: calc(100% - 16px);
}
.searchbar .searchInput .SearchresultBox li {
  list-style: none;
  padding: 8px 12px;
  display: none;
  width: 100%;
  cursor: default;
  border-radius: 3px;
}
.searchbar .searchInput .SearchresultBox li:hover {
  background: #efefef;
}
.searchbar .searchInput .searchlens {
  position: absolute;
  right: 13px;
  top: 13px;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 55px;
  font-size: 20px;
  fill: #644bff;
  cursor: pointer;
}
.searchbar .activesearchbox input {
  border-radius: 5px 5px 0 0;
}
.searchbar .activesearchbox .SearchresultBox {
  padding: 10px 8px;
  opacity: 1;
  pointer-events: auto;
  border-radius: 0 0 5px 5px;
}
.searchbar .activesearchbox .SearchresultBox li {
  display: block;
}/*# sourceMappingURL=searchbar.css.map */