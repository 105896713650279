.product_review {
  .prod_write_a_review {
    padding-top: 2rem;
    .prod_write_review_wrapper {
      .prod_write_review_form {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        .write_prod_rev_form_group {
          width: 100%;
          margin-bottom: 1rem;
          label {
            font-size: 1rem;
            span {
              color: red;
            }
          }
          .review_prod_input {
            padding: 5px;
            width: calc(100% - 5px);
            max-width: calc(100% - 5px);
            min-width: calc(100% - 5px);
            min-height: 200px;
            height: 200px;
            max-height: 300px;
            border-radius: 5px;
            border: 1px solid #000;
            outline: none;
            margin-top: 10px;
            font-size: 1rem;
          }
        }
        .prod_post_review_btn {
          all: unset;
          width: max-content;
          padding: 10px 30px;
          background-color: #fff;
          border-radius: 43px;
          text-align: center;
          color: #fff;
          cursor: pointer;
          white-space: nowrap;
          background: linear-gradient(
            to bottom right,
            #9651ff,
            #5c00e6
          ) !important;
          margin-bottom: 1rem;
        }
      }
    }
  }
  .prod_previews_title {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  .product_reviews_container {
    .prod_review_item {
      margin-bottom: 2rem;
      .prod_review_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .prod_review_header_left {
          flex: 1;
          .prod_reviewer_profile {
            display: flex;
            align-items: center;
            gap: 1rem;
            .prod_reviewer_image {
              height: 35px;
              width: 35px;
              border-radius: 50%;
              overflow: hidden;
            }
            .prod_reviewer_name {
              font-size: 1rem;
            }
          }
          .review_prod_meta {
            display: flex;
            align-items: center;
            gap: 1rem;
            .review_prod_date {
              font-size: 0.8rem;
            }
          }
        }
        .prod_review_header_right {
          position: relative;
          .ellipsis_vertical_review_more {
            height: 20px;
            width: 20px;
            cursor: pointer;
            border-radius: 50%;
            overflow: hidden;
            padding: 10px;
            &:hover {
              background-color: #e6e6e6;
            }
          }
          .prod_review_more_options {
            position: absolute;
            top: 30px;
            right: 20px;
            width: min-content;
            height: min-content;
            border-radius: 5px;
            background-color: #fff;
            overflow: hidden;
            transition: 0.3s ease-in-out;
            .prod_review_more_option_item {
              padding: 10px;
              cursor: pointer;
              display: flex;
              align-items: center;
              gap: 10px;
              &:hover {
                background-color: #e2f1ff;
              }
              .prod_review_flag {
                height: 20px;
                width: 20px;
              }
              span {
                white-space: nowrap;
                font-size: 0.9rem;
                user-select: none;
              }
            }
          }
        }
      }
      .prod_review_body {
        .prod_preview_text {
          font-size: 1rem;
          margin: 1rem 0 0.6rem 0;
        }
        .prod_review_is_helpful {
          display: flex;
          align-items: center;
          gap: 0.3rem;
          margin: 0.6rem 0;
          .review_helpful_choices {
            .review_helpful_choice {
              padding: 3px 6px;
              border-radius: 23px;
              border: 1px solid #000;
              margin-left: 1rem;
              font-size: 0.7rem;
              cursor: pointer;
              &:hover {
                background-color: #e4e4e4;
              }
            }
          }
        }
      }
    }
  }
}
