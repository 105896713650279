.showcaseitem_kibz .showcaseitem_kibz_wrapper {
  width: 100%;
}
@media (max-width: 420px) {
  .showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_top_catalog {
    height: 150px !important;
  }
}
.showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_top {
  position: relative;
  height: 200px;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
}
.showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_top .showcaseitem_kibz_product_image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_top .showcaseitem_kibz_product_image .showcaseitem_kibz_image_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_top .showcaseitem_kibz_product_image .showcaseitem_kibz_image_wrapper .showcaseitem_kibz_image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
  -o-object-position: 0 0;
     object-position: 0 0;
  aspect-ratio: auto 100/100;
  background-repeat: no-repeat;
  border-radius: 2px;
  cursor: pointer;
  -o-object-fit: fill;
     object-fit: fill;
  overflow: clip;
  overflow-clip-margin: content-box;
  transition: transform 0.3s ease-in-out 0.2ms;
}
.showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_top .showcaseitem_kibz_product_image .showcaseitem_kibz_image_wrapper .howcaseitem_kibz_image_overlay {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0%, 0%) !important;
  width: 100%;
  height: 100% !important;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}
.showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_top .showcaseitem_kibz_add_to_cart {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer !important;
  background-color: rgba(255, 255, 255, 0.5882352941);
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 25px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: -moz-min-content !important;
  height: min-content !important;
  transition: 0.3s ease;
}
.showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_top .showcaseitem_kibz_add_to_cart .plus {
  height: 15px;
  width: 15px;
  fill: #5c00e6;
}
.showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_top .showcaseitem_kibz_add_to_cart .showcaseitem_kibz_add_to_cart_texts {
  font-size: 0.7rem;
  white-space: nowrap;
  opacity: 1;
  margin-left: 5px !important;
  color: #5c00e6;
}
.showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_top .showcaseitem_kibz_discount_percentage {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: -moz-max-content;
  width: max-content;
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, 0.8588235294);
  border-radius: 25px;
  margin: 5px;
  font-size: 0.7rem;
  white-space: nowrap;
  color: #5c00e6;
  border: 1px solid #5c00e6;
}
.showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_bottom {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}
.showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_bottom .showcaseitem_kibz_profile {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  cursor: pointer;
}
@media (max-width: 480px) {
  .showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_bottom .showcaseitem_kibz_profile {
    display: none !important;
  }
}
.showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_bottom .showcaseitem_kibz_profile img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 0 0;
     object-position: 0 0;
}
.showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_bottom .showcaseitem_kibz_meta {
  flex: 1;
}
@media (max-width: 480px) {
  .showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_bottom .showcaseitem_kibz_meta {
    width: 100%;
  }
}
.showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_bottom .showcaseitem_kibz_meta .showcaseitem_kibz_product_name {
  font-size: 0.9rem;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-wrap: break-word;
  display: -webkit-box;
  line-height: 1.2rem !important;
  max-height: 2.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  cursor: pointer;
  color: #000000;
  font-weight: 400;
}
.showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_bottom .showcaseitem_kibz_meta .showcaseitem_kibz_price_wrapper {
  display: flex;
  align-items: center;
}
@media (max-width: 480px) {
  .showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_bottom .showcaseitem_kibz_meta .showcaseitem_kibz_price_wrapper {
    flex-direction: column !important;
    align-items: flex-start;
  }
}
.showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_bottom .showcaseitem_kibz_meta .showcaseitem_kibz_price_wrapper .showcaseitem_kibz_product_price {
  width: -moz-max-content;
  width: max-content;
  padding: 5px 10px;
  background-color: #fff;
  border-radius: 25px;
  margin: 5px;
  font-size: 0.7rem;
  white-space: nowrap;
}
@media (max-width: 480px) {
  .showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_bottom .showcaseitem_kibz_meta .showcaseitem_kibz_price_wrapper .showcaseitem_kibz_product_price {
    margin-left: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 456px) {
  .showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_bottom .showcaseitem_kibz_meta .showcaseitem_kibz_price_wrapper .showcaseitem_kibz_product_price .inform_more_on_smaller_screens {
    display: none;
  }
}
@media (max-width: 456px) {
  .showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_bottom .showcaseitem_kibz_meta .showcaseitem_kibz_price_wrapper .showcaseitem_kibz_product_price .minify_on_smaller_screens {
    display: none;
  }
}
.showcaseitem_kibz .showcaseitem_kibz_wrapper .showcaseitem_kibz_bottom .showcaseitem_kibz_meta .showcaseitem_kibz_price_wrapper .showcaseitem_kibz_product_price_discount {
  font-size: 0.65rem;
  white-space: nowrap;
  text-decoration: line-through;
}/*# sourceMappingURL=showcaseitem.css.map */