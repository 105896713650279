:root {
  --swiper-navigation-color: #27ff0a;
}

.categoriesSec {
  height: -moz-max-content !important;
  height: max-content !important;
}
.categoriesSec .catcont {
  margin: 0 5%;
}
.categoriesSec .catcont .catSwiper {
  margin-bottom: 20px;
}
.categoriesSec .catcont .catSwiper .catswipeslide .catproperties {
  background-color: #c2c2ff;
  padding: 5px;
  cursor: pointer;
}
@media (max-width: 480px) {
  .categoriesSec .catcont .catSwiper .catswipeslide .catproperties {
    width: calc(100% - 10px);
  }
}
.categoriesSec .catcont .catSwiper .catswipeslide .catproperties .catpropImagecont {
  width: 100%;
  height: 170px;
  overflow: hidden !important;
}
.categoriesSec .catcont .catSwiper .catswipeslide .catproperties .catpropImagecont .catsliderImagePreview {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.categoriesSec .catcont .catSwiper .catswipeslide .catproperties p {
  font-family: Roboto;
  text-align: center;
  margin-top: 5px;
}

.swiper-button-next {
  background-image: url(../../assets/svg/arrow-right.svg) !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  border: 5px solid #fff;
}

.swiper-button-prev {
  background-image: url(../../assets/svg/arrow-left.svg) !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  border: 5px solid #fff;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  display: none !important;
}/*# sourceMappingURL=categories.css.map */