.profile .profileContainer {
  margin: 0 5%;
}
.profile .profileContainer .profileWrapper {
  display: flex;
  padding: 10px 0;
}
.profile .profileContainer .profileWrapper .profNav {
  width: 250px;
  border: 1px solid #000;
  border-radius: 5px;
  margin-right: 20px;
}
.profile .profileContainer .profileWrapper .profNav .profunlist .profNavLinkActive {
  background-color: rgba(0, 0, 0, 0.4078431373);
}
.profile .profileContainer .profileWrapper .profNav .profunlist .profNavLink {
  padding: 15px 20px;
  margin: 5px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.profile .profileContainer .profileWrapper .profNav .profunlist .profNavLink:nth-child(1) {
  margin: 0;
}
.profile .profileContainer .profileWrapper .profNav .profunlist .profNavLink:hover {
  background-color: rgba(0, 0, 0, 0.1647058824);
}
.profile .profileContainer .profileWrapper .profNav .profunlist .profNavLink .profIcNav {
  height: 20px;
  width: 20px;
  fill: #000;
  margin-right: 10px;
}
.profile .profileContainer .profileWrapper .profNav .profunlist .profNavLink .profNavLinkCont {
  font-size: 1.1rem;
  white-space: nowrap !important;
}
.profile .profileContainer .profileWrapper .profPlayarea {
  flex: 1;
}
.profile .profileContainer .profileWrapper .profPlayarea .accoverview {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.4705882353);
  border-radius: 5px;
  height: 600px;
}
.profile .profileContainer .profileWrapper .profPlayarea .accoverview .accrevtop {
  padding: 12px 10px;
  padding-left: 40px;
  border-bottom: 1px solid rgba(188, 188, 188, 0.6);
}
.profile .profileContainer .profileWrapper .profPlayarea .accoverview .accrevtop h1 {
  font-size: 1.5rem;
}
.profile .profileContainer .profileWrapper .profPlayarea .accoverview .accrevCont {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.profile .profileContainer .profileWrapper .profPlayarea .accoverview .accrevCont .accovdetcont {
  border: 1px solid rgba(188, 188, 188, 0.6);
  border-radius: 5px;
  width: 45%;
  margin: 20px 0;
}
.profile .profileContainer .profileWrapper .profPlayarea .accoverview .accrevCont .accovdetcont .acovtopdet {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid rgba(188, 188, 188, 0.6);
}
.profile .profileContainer .profileWrapper .profPlayarea .accoverview .accrevCont .accovdetcont .acovtopdet h2 {
  font-size: 1rem;
}
.profile .profileContainer .profileWrapper .profPlayarea .accoverview .accrevCont .accovdetcont .acovtopdet .profaccovIcNav {
  height: 10px;
  width: 10px;
  fill: #61c5ff;
  margin-right: 10px;
}
.profile .profileContainer .profileWrapper .profPlayarea .accoverview .accrevCont .accovdetcont .accovdetcontdet {
  padding: 10px;
}/*# sourceMappingURL=profile.css.map */