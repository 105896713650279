.auth {
  height: 100vh;
  .auth_container {
    position: relative;
    height: 100%;
    .auth_wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      width: 55%;
      height: 75vh;
      box-shadow: #00000059 0px 5px 15px;
      border-radius: 8px;
      border-top: 2px solid #5a8eff;
      overflow: hidden;
      .auth_left {
        width: 50%;
        position: relative;
        height: 100%;
        background-color: #ffffff;
        background-image: linear-gradient(180deg, #fff, #dfdfff);

        .auth_login_box {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 80%;
          padding: 5px;
          h1 {
            margin: 1.2rem 0;
            color: rgba(72, 76, 122, var(--text-opacity));
          }
          .auth_login_form {
            .auth_form_group {
              margin-bottom: 1.2rem;
              .auth_label {
                font-size: 0.8rem;
                span {
                  color: red;
                }
              }
              .auth_login_input {
                padding: 10px;
                margin-top: 3px;
                border: 1px solid #2c2c2c;
                border-radius: 5px;
                outline: none;
                width: calc(100% - 20px);
              }
            }
            .auth_info {
              text-align: center;
              span {
                color: #167bff;
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            .horizontal_rule_container {
              display: flex;
              align-items: center;
              margin: 1rem 0;
              .horizontal_rule {
                flex: 1;
                height: 1px;
                background-color: #595959;
                margin: 0 10px;
              }
              .or_text {
                font-weight: 500;
                margin-top: -4px;
              }
            }
            .auth_btn_submit {
              padding: 10px 0;
              width: 100%;
              outline: none;
              border: none;
              background-color: #167bff;
              color: #fff;
              font-size: 1rem;
              margin: 1rem auto;
              margin-top: 0;
              border-radius: 5px;
              cursor: pointer;
            }
            .auth_other_login_options {
              margin: 1rem auto;
            }
          }
        }
      }
      .auth_right {
        width: 50%;
        height: 100%;
        position: relative;
        .auth_intro_image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .auth_right_wrapper {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          padding: 10px;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .auth_home_logo_link {
            font-family: 'Itim', cursive;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
