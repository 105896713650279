.dropdown {
  position: relative;
  z-index: 1000;
  .drcat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    padding: 5px;
    position: relative;
    border: 10px solid;
    border-image-slice: 1;
    border-width: 2px;
    border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
    border-radius: 5px;
    cursor: pointer;
    p {
      color: #fff;
      font-size: 0.9rem;
      font-family: Roboto;
      cursor: pointer;
    }
    span {
      .angdown {
        display: block;
        height: 15px;
        width: 15px;
        fill: #fff;
      }
      .angup {
        transform: rotate(180deg);
      }
    }
  }
  .dropnav {
    position: absolute;
    .drpanglePointer {
      width: 30px;
      height: 30px;
      background-color: #ffffff;
      transform: rotate(45deg);
      margin-left: 2rem;
      margin-top: 8px;
      @media (min-width: 1400px) {
        left: 17%;
      }
    }
    .drpnavcont {
      margin: 0 5px;
      margin-top: -15px;
      min-height: 200px;
      max-height: 600px;
      min-width: 400px;
      width: min-content;
      max-width: 70vw;
      background-color: #fff;
      overflow: hidden !important;
      display: flex;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      .drpLeft {
        width: min-content;
        height: 100%;
        .drpnavigation {
          .drpleftlink {
            display: flex;
            align-items: center;
            margin: 15px 0;
            color: #242424;
            fill: #242424;
            padding-left: 10px;
            cursor: pointer;
            &:hover {
              color: #743ad5 !important;
              fill: #743ad5 !important;
            }
            .drpleftNavIc {
              height: 24px;
              width: 24px;
              fill: inherit;
              margin-right: 10px;
            }
            p {
              font-family: Roboto;
              color: inherit;
              white-space: nowrap;
              padding-right: 20px;
            }
          }
        }
      }
      .drpRight {
        flex-grow: 1;
        border-left: 1px solid #7c7c7c;
        .drpRSections {
          padding: 10px;
          display: flex;
          flex-wrap: wrap;
          gap: 2rem;
          .drpsectionslists {
            h2 {
              text-transform: uppercase;
              padding-bottom: 10px;
              border-bottom: 1px solid #646464;
              font-size: 1.2rem;
            }
            ul {
              li {
                p {
                  padding: 6px;
                  cursor: pointer;
                  &:hover {
                    color: #743ad5;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
