.landing {
  height: auto;
  margin-bottom: 20px;
  .container {
    margin: 0 5%;
    padding-top: 20px;
    .landLayout {
      display: flex;
      // display: grid;
      // grid-template-columns: repeat(3, 1fr);
      // grid-auto-rows: minmax(250px, auto);
      gap: 1rem;
      @media (max-width: 480px) {
        flex-direction: column;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: minmax(auto, auto);
      }
      .slideshow {
        flex: 1;
        border-radius: 5px;
        overflow: hidden !important;
        grid-row: span 2;
        grid-column: span 2;
        @media (max-width: 480px) {
          grid-row: span 1;
          grid-column: span 1;
        }
      }
      .landhther {
        width: 25%;
        height: 400px;
        overflow: hidden;
        @media (max-width: 480px) {
          width: 100%;
          height: min-content;
        }
        .landHigh {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          height: 100%;
          padding: 10px;
          background-image: linear-gradient(180deg, #fff, #c2c2ff);
          --text-opacity: 1;
          color: #484c7a;
          color: rgba(72, 76, 122, var(--text-opacity));
          border-radius: 4px;
          border-top: 2px solid #1fadff;
          box-shadow: 0 4px 11px 0 rgb(37 44 97 / 15%),
            0 1px 3px 0 rgb(93 100 148 / 20%);
          .lndhgone {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            padding: 5px;
            margin-left: 5px;
            &:nth-child(1) {
              margin-top: 10px;
            }
            .cusomercare {
              height: 40px;
              width: 40px;
            }
            .lndonetxts {
              h3 {
                text-transform: uppercase;
                font-size: 0.9rem;
              }
              p {
                font-size: 0.8rem;
              }
            }
          }
          .linktoallproducts {
            .startbrowsing {
              outline: none;
              border: none;
              padding: 20px 40px;
              text-align: center;
              font-weight: 800;
              font-size: 1rem;
              text-transform: uppercase;
              display: block;
              margin: 0 auto;
              margin-top: 1.5rem;
              background-color: #1fadff;
              color: #fff;
              cursor: pointer;
              margin-bottom: 0px;
              border-radius: 5px;
              box-shadow: 0 4px 11px 0 rgb(37 44 97 / 15%),
                0 1px 3px 0 rgb(93 100 148 / 20%);
              &:hover {
                transform: scale(1.07);
              }
            }
          }
          .lndhighimgwrapper {
            height: 100%;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
