.deliverydropdown {
  .deliverydropdown_container {
    .deliverydropdown_wrapper {
      .deliverydropdown_selected_locations {
        margin-bottom: 10px;
        .del_sel_loc_list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          position: relative;
          .selected_loc_item {
            border: 1px solid #5a8eff;
            background-color: #e0cfff;
            height: 40px;
            width: 80px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            text-align: center;
            position: relative;
            p {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              overflow: hidden;
              -webkit-line-clamp: 2;
              line-height: 1;
              text-overflow: ellipsis;
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}
