.meta_selector_parent .meta_selector {
  margin-right: 10px;
  cursor: pointer;
}
.meta_selector_parent .meta_selector .meta_selector_container .meta_selector_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.meta_selector_parent .meta_selector .meta_selector_container .meta_selector_wrapper .meta_selector_inner_wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.meta_selector_parent .meta_selector .meta_selector_container .meta_selector_wrapper .meta_selector_inner_wrapper .meta_selector_flag {
  height: 15px;
  width: 20px;
  overflow: hidden;
}
.meta_selector_parent .meta_selector .meta_selector_container .meta_selector_wrapper .meta_selector_inner_wrapper .meta_selector_flag img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.meta_selector_parent .meta_selector .meta_selector_container .meta_selector_wrapper .meta_selector_inner_wrapper span {
  font-size: 0.7rem;
  text-align: center;
  margin-right: -3px;
  margin-top: 2px;
}
.meta_selector_parent .meta_selector .meta_selector_container .meta_selector_wrapper .meta_selector_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
}
.meta_selector_parent .meta_selector .meta_selector_container .meta_selector_wrapper .meta_selector_right .meta_selector_iso_name span {
  font-weight: 500;
  margin: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.meta_selector_parent .meta_selector .meta_selector_container .meta_selector_wrapper .meta_selector_right .meta_selector_angle_down {
  height: 10px;
  width: 10px;
  fill: #fff;
  margin-bottom: -3px;
}
.meta_selector_parent .meta_selector .meta_selector_container .meta_selector_drop_down_parent {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  background-color: rgba(12, 12, 12, 0.4392156863);
}
.meta_selector_parent .meta_selector .meta_selector_container .meta_selector_drop_down_parent .meta_selector_drop_down {
  position: absolute;
  top: 65px;
  right: 8%;
  z-index: 999;
  height: 500px;
  width: 300px;
  background-color: #fff;
  border: 1px solid #000;
  box-shadow: rgba(0, 0, 0, 0.3490196078) 0px 5px 15px;
}
.meta_selector_parent .meta_selector .meta_selector_container .meta_selector_drop_down_parent .meta_selector_drop_down .meta_selector_ordered_list_countries {
  height: 50%;
  overflow-y: scroll;
  position: relative;
}
.meta_selector_parent .meta_selector .meta_selector_container .meta_selector_drop_down_parent .meta_selector_drop_down .meta_selector_ordered_list_countries .meta_selector_ordered_list_items {
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 5px 0;
  overflow: hidden;
}
.meta_selector_parent .meta_selector .meta_selector_container .meta_selector_drop_down_parent .meta_selector_drop_down .meta_selector_ordered_list_countries .meta_selector_ordered_list_items:hover {
  background-color: #c3c3c3;
}
.meta_selector_parent .meta_selector .meta_selector_container .meta_selector_drop_down_parent .meta_selector_drop_down .meta_selector_ordered_list_countries .meta_selector_ordered_list_items .meta_selector_country_name {
  color: #000;
  white-space: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
@media (max-width: 830px) {
  .meta_selector_parent .meta_selector .meta_selector_container .meta_selector_drop_down_parent .meta_selector_drop_down .meta_selector_ordered_list_countries .meta_selector_ordered_list_items .meta_selector_country_name {
    display: block !important;
  }
}
.meta_selector_parent .meta_selector .meta_selector_container .meta_selector_drop_down_parent .meta_selector_drop_down .meta_selector_ordered_list_currency {
  height: 50%;
  border-top: 1px solid black;
  overflow-y: scroll;
  position: relative;
}
.meta_selector_parent .meta_selector .meta_selector_container .meta_selector_drop_down_parent .meta_selector_drop_down .meta_selector_ordered_list_currency .meta_selector_ordered_list_items {
  padding: 0 10px;
  display: flex;
  align-items: center;
  margin: 5px 0;
  overflow: hidden;
  justify-content: left;
}
.meta_selector_parent .meta_selector .meta_selector_container .meta_selector_drop_down_parent .meta_selector_drop_down .meta_selector_ordered_list_currency .meta_selector_ordered_list_items:hover {
  background-color: #c3c3c3;
}
.meta_selector_parent .meta_selector .meta_selector_container .meta_selector_drop_down_parent .meta_selector_drop_down .meta_selector_ordered_list_currency .meta_selector_ordered_list_items .meta_selector_symbol {
  width: -moz-min-content;
  width: min-content;
  text-align: center;
}
@media (max-width: 830px) {
  .meta_selector_parent .meta_selector .meta_selector_container .meta_selector_drop_down_parent .meta_selector_drop_down .meta_selector_ordered_list_currency .meta_selector_ordered_list_items .meta_selector_symbol {
    display: block !important;
  }
}
.meta_selector_parent .meta_selector .meta_selector_container .meta_selector_drop_down_parent .meta_selector_drop_down .meta_selector_ordered_list_currency .meta_selector_ordered_list_items .meta_selector_symbol span {
  color: #000;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
@media (max-width: 830px) {
  .meta_selector_parent .meta_selector .meta_selector_container .meta_selector_drop_down_parent .meta_selector_drop_down .meta_selector_ordered_list_currency .meta_selector_ordered_list_items .meta_selector_symbol span {
    display: block !important;
  }
}
@media (max-width: 830px) {
  .meta_selector_parent .meta_selector .meta_selector_container .meta_selector_drop_down_parent .meta_selector_drop_down .meta_selector_ordered_list_currency .meta_selector_ordered_list_items span {
    display: block !important;
  }
}
.meta_selector_parent .meta_selector .meta_selector_container .meta_selector_drop_down_parent .meta_selector_drop_down .meta_selector_ordered_list_currency .meta_selector_ordered_list_items .meta_selector_currency_name {
  color: #000;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
@media (max-width: 830px) {
  .meta_selector_parent .meta_selector .meta_selector_container .meta_selector_drop_down_parent .meta_selector_drop_down .meta_selector_ordered_list_currency .meta_selector_ordered_list_items .meta_selector_currency_name {
    display: block !important;
  }
}

.meta_selector_filter_options {
  width: calc(100% - 10px);
  padding: 7px 5px;
  position: sticky;
  top: 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  outline: none;
  border: none;
}/*# sourceMappingURL=metaselector.css.map */