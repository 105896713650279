.showcase_content {
  width: 100%;
  margin-bottom: 20px;
  .showcasecont_header {
    padding: 5px;
    background-color: #1fadff;
    margin-bottom: 5px;
    border-radius: 5px;
    .showcasecont_header_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .showcasecont_header_title {
        font-size: 1.4rem;
        margin-left: 10px;
        color: aliceblue;
      }
      .showcase_header_see_all {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-right: 10px;
        h2 {
          color: aliceblue;
          font-size: 0.8rem;
          text-transform: uppercase;
        }
        .showcase_arrow_right {
          height: 15px;
          width: 15px;
          fill: aliceblue;
          transition: 0.4s ease-in-out;
          cursor: pointer;
          &:hover {
            transform: translateX(4px);
          }
        }
      }
    }
  }
}

// .manipulate_swiper_wrapper > .swiper-wrapper,
// .manipulate_swiper_wrapper > .catswipeslide,
// .manipulate_swiper_wrapper > .swiper-slide-active {
//   flex-wrap: wrap;
//   justify-content: flex-start;
//   transform: unset !important;
//   width: unset !important;
// }
