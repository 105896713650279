.sellwithus {
  min-height: calc(100vh - 70px);
}
.sellwithus .sellwithuscontainer {
  margin: 0 10%;
}
@media (max-width: 1400px) {
  .sellwithus .sellwithuscontainer {
    margin: 0 5%;
  }
}
.sellwithus .sellwithuscontainer .swulanding .swubreadcrumb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}
.sellwithus .sellwithuscontainer .swulanding .swubreadcrumb h1 {
  font-family: AEmb, Helvetica, Hiragino Kaku Gothic Pro, sans-serif;
  color: #232f3e;
}
@media (max-width: 480px) {
  .sellwithus .sellwithuscontainer .swulanding .swubreadcrumb h1 {
    font-size: 1.6rem;
  }
}
.sellwithus .sellwithuscontainer .swulanding .swubreadcrumb .swusignupbtn {
  outline: none;
  border: none;
  background: linear-gradient(to bottom right, #14a9ff, #0088f0) !important;
  border-radius: 23px;
  color: #fff !important;
  font-family: gibsonsemibold, Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  line-height: 1.4rem;
  padding: 10px 20px;
  text-align: center;
  transition: 0.4s;
  cursor: pointer;
  white-space: nowrap;
}
.sellwithus .sellwithuscontainer .swulanding .swulndingmain {
  width: 100%;
  margin: 10% 0;
  margin-bottom: 20%;
  display: flex;
  justify-content: space-evenly;
}
@media (max-width: 1400px) {
  .sellwithus .sellwithuscontainer .swulanding .swulndingmain {
    margin: 5% 0;
  }
}
@media (max-width: 480px) {
  .sellwithus .sellwithuscontainer .swulanding .swulndingmain {
    flex-direction: column-reverse;
  }
}
.sellwithus .sellwithuscontainer .swulanding .swulndingmain .swulefttexts {
  flex: 1;
}
@media (max-width: 1280px) {
  .sellwithus .sellwithuscontainer .swulanding .swulndingmain .swulefttexts {
    width: 100%;
    overflow: hidden;
  }
}
.sellwithus .sellwithuscontainer .swulanding .swulndingmain .swulefttexts h1 {
  font-size: 80px;
  line-height: 96px;
  font-weight: 900;
  font-family: AEmb, Helvetica, Hiragino Kaku Gothic Pro, sans-serif;
  color: #232f3e;
}
@media (max-width: 1400px) {
  .sellwithus .sellwithuscontainer .swulanding .swulndingmain .swulefttexts h1 {
    font-size: 70px;
    line-height: 79px;
  }
}
@media (max-width: 480px) {
  .sellwithus .sellwithuscontainer .swulanding .swulndingmain .swulefttexts h1 {
    font-size: 4rem;
    line-height: 3.5rem;
  }
}
.sellwithus .sellwithuscontainer .swulanding .swulndingmain .swulefttexts p {
  font-size: 1.4rem;
  line-height: 30px;
  font-family: AEmb, Helvetica, Hiragino Kaku Gothic Pro, sans-serif;
  margin: 20px 0;
}
@media (max-width: 1400px) {
  .sellwithus .sellwithuscontainer .swulanding .swulndingmain .swulefttexts p {
    margin: 10px 0;
  }
}
.sellwithus .sellwithuscontainer .swulanding .swulndingmain .swulefttexts .swusignupbtn {
  outline: none;
  border: none;
  background: linear-gradient(to bottom right, #14a9ff, #0088f0) !important;
  border-radius: 35px;
  color: #fff !important;
  font-family: gibsonsemibold, Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  line-height: 1.4rem;
  padding: 20px 40px;
  text-align: center;
  transition: 0.4s;
  cursor: pointer;
  margin: 10px 0;
}
.sellwithus .sellwithuscontainer .swulanding .swulndingmain .swulefttexts span {
  font-size: 0.8rem;
}
.sellwithus .sellwithuscontainer .swulanding .swulndingmain .swurightimage {
  width: -moz-min-content;
  width: min-content;
}
@media (max-width: 1280px) {
  .sellwithus .sellwithuscontainer .swulanding .swulndingmain .swurightimage {
    display: none !important;
  }
}
.sellwithus .sellwithuscontainer .swulanding .swulndingmain .swurightimage .swusvgic {
  margin-top: -30px;
  transform: rotate(-15deg);
  height: 80%;
}
.sellwithus .sellwithuscontainer .swulanding .swubannerbtm {
  margin: 5%;
}
@media (max-width: 480px) {
  .sellwithus .sellwithuscontainer .swulanding .swubannerbtm {
    margin: 0;
  }
}
.sellwithus .sellwithuscontainer .swulanding .swubannerbtm .swubannerwrapper {
  padding: 40px;
  background-color: #09090f;
  border-radius: 30px;
}
@media (max-width: 480px) {
  .sellwithus .sellwithuscontainer .swulanding .swubannerbtm .swubannerwrapper {
    padding: 20px;
  }
}
.sellwithus .sellwithuscontainer .swulanding .swubannerbtm .swubannerwrapper h1 {
  font-size: 4rem;
  line-height: 5rem;
  font-weight: 900;
  font-family: AEmb, Helvetica, Hiragino Kaku Gothic Pro, sans-serif;
  color: #daebff;
}
@media (max-width: 480px) {
  .sellwithus .sellwithuscontainer .swulanding .swubannerbtm .swubannerwrapper h1 {
    font-size: 2rem;
    line-height: 2rem;
  }
}
.sellwithus .sellwithuscontainer .swulanding .swubannerbtm .swubannerwrapper p {
  font-size: 1.4rem;
  line-height: 20px;
  font-family: AEmb, Helvetica, Hiragino Kaku Gothic Pro, sans-serif;
  margin: 20px 0;
  font-weight: 600;
  color: #daebff;
}
@media (max-width: 480px) {
  .sellwithus .sellwithuscontainer .swulanding .swubannerbtm .swubannerwrapper p {
    font-size: 1rem;
    font-weight: 300;
    margin: 10px 0;
  }
}
.sellwithus .sellwithuscontainer .swulanding .swubannerbtm .swubannerwrapper .swusignupbtn {
  outline: none;
  border: none;
  background: linear-gradient(to bottom right, #14a9ff, #0088f0) !important;
  border-radius: 35px;
  color: #fff !important;
  font-family: gibsonsemibold, Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  line-height: 1.4rem;
  padding: 20px 40px;
  text-align: center;
  transition: 0.4s;
  cursor: pointer;
  margin: 10px 0;
}
@media (max-width: 480px) {
  .sellwithus .sellwithuscontainer .swulanding .swubannerbtm .swubannerwrapper .swusignupbtn {
    padding: 10px 20px;
  }
}
.sellwithus .sellwithuscontainer .swulanding .swubannerbtm .swubannerwrapper span {
  font-size: 0.8rem;
  color: #daebff;
}
.sellwithus .sellwithuscontainer .sellwithusregistershop,
.sellwithus .sellwithuscontainer .sellwithusloginshop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(27, 27, 27, 0.537254902);
}
.sellwithus .sellwithuscontainer .sellwithusregistershop .regshopform,
.sellwithus .sellwithuscontainer .sellwithusloginshop .regshopform {
  height: 100%;
  width: 100%;
  position: relative;
}
.sellwithus .sellwithuscontainer .sellwithusregistershop .regshopform .regformsh,
.sellwithus .sellwithuscontainer .sellwithusloginshop .regshopform .regformsh {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 700px;
  pointer-events: all;
}
.sellwithus .sellwithuscontainer .sellwithusregistershop .regshopform .regformsh .regformshwrapper,
.sellwithus .sellwithuscontainer .sellwithusloginshop .regshopform .regformsh .regformshwrapper {
  padding: 20px;
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  position: relative;
}
.sellwithus .sellwithuscontainer .sellwithusregistershop .regshopform .regformsh .regformshwrapper .xmarkicswu,
.sellwithus .sellwithuscontainer .sellwithusloginshop .regshopform .regformsh .regformshwrapper .xmarkicswu {
  position: absolute;
  top: 0;
  right: 0;
  height: 20px;
  width: 20px;
  padding: 10px;
  cursor: pointer;
}
.sellwithus .sellwithuscontainer .sellwithusregistershop .regshopform .regformsh .regformshwrapper h1,
.sellwithus .sellwithuscontainer .sellwithusloginshop .regshopform .regformsh .regformshwrapper h1 {
  font-size: 0.9rem;
}
.sellwithus .sellwithuscontainer .sellwithusregistershop .regshopform .regformsh .regformshwrapper .shreg_group_flexer,
.sellwithus .sellwithuscontainer .sellwithusloginshop .regshopform .regformsh .regformshwrapper .shreg_group_flexer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}
.sellwithus .sellwithuscontainer .sellwithusregistershop .regshopform .regformsh .regformshwrapper .shreg-group,
.sellwithus .sellwithuscontainer .sellwithusloginshop .regshopform .regformsh .regformshwrapper .shreg-group {
  margin: 10px 0;
  width: 100%;
}
.sellwithus .sellwithuscontainer .sellwithusregistershop .regshopform .regformsh .regformshwrapper .shreg-group label,
.sellwithus .sellwithuscontainer .sellwithusloginshop .regshopform .regformsh .regformshwrapper .shreg-group label {
  font-size: 0.8rem;
}
.sellwithus .sellwithuscontainer .sellwithusregistershop .regshopform .regformsh .regformshwrapper .shreg-group label span,
.sellwithus .sellwithuscontainer .sellwithusloginshop .regshopform .regformsh .regformshwrapper .shreg-group label span {
  color: red;
}
.sellwithus .sellwithuscontainer .sellwithusregistershop .regshopform .regformsh .regformshwrapper .shreg-group .shfrminput,
.sellwithus .sellwithuscontainer .sellwithusloginshop .regshopform .regformsh .regformshwrapper .shreg-group .shfrminput {
  margin-top: 5px;
  padding: 10px;
  outline: none;
  border-radius: 5px;
  width: calc(100% - 20px);
  border: 1px solid #000;
}
.sellwithus .sellwithuscontainer .sellwithusregistershop .regshopform .regformsh .regformshwrapper .shreg-group p,
.sellwithus .sellwithuscontainer .sellwithusloginshop .regshopform .regformsh .regformshwrapper .shreg-group p {
  font-size: 0.6rem;
  color: #363636;
}
.sellwithus .sellwithuscontainer .sellwithusregistershop .regshopform .regformsh .regformshwrapper .btnwrapswucont,
.sellwithus .sellwithuscontainer .sellwithusloginshop .regshopform .regformsh .regformshwrapper .btnwrapswucont {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.sellwithus .sellwithuscontainer .sellwithusregistershop .regshopform .regformsh .regformshwrapper .btnwrapswucont .regswubtn,
.sellwithus .sellwithuscontainer .sellwithusloginshop .regshopform .regformsh .regformshwrapper .btnwrapswucont .regswubtn {
  outline: none;
  border: none;
  background: linear-gradient(to bottom right, #14a9ff, #0088f0) !important;
  border-radius: 35px;
  color: #fff !important;
  font-family: gibsonsemibold, Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  line-height: 1.4rem;
  padding: 15px 35px;
  text-align: center;
  transition: 0.4s;
  cursor: pointer;
  margin: 10px 0;
  margin-bottom: 0;
}/*# sourceMappingURL=sellwithus.css.map */