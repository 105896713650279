.product_details {
  position: relative;
}
.product_details .product_details_container {
  margin: 20px 5%;
}
.product_details .product_details_container .product_details_wrapper {
  display: flex;
  flex-direction: row;
}
@media (max-width: 480px) {
  .product_details .product_details_container .product_details_wrapper {
    flex-direction: column;
  }
}
.product_details .product_details_container .product_details_wrapper .product_details_left {
  width: 40%;
}
@media (max-width: 480px) {
  .product_details .product_details_container .product_details_wrapper .product_details_left {
    width: 100%;
  }
}
.product_details .product_details_container .product_details_wrapper .product_details_left .product_details_image_wrapper {
  display: flex;
  flex-direction: row;
  min-height: 40vh;
}
@media (max-width: 480px) {
  .product_details .product_details_container .product_details_wrapper .product_details_left .product_details_image_wrapper {
    flex-direction: column-reverse;
  }
}
.product_details .product_details_container .product_details_wrapper .product_details_left .product_details_image_wrapper .product_details_image_sidebar {
  margin-right: 20px;
  width: 70px;
}
@media (max-width: 480px) {
  .product_details .product_details_container .product_details_wrapper .product_details_left .product_details_image_wrapper .product_details_image_sidebar {
    width: 100%;
    margin-right: 0;
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
.product_details .product_details_container .product_details_wrapper .product_details_left .product_details_image_wrapper .product_details_image_sidebar .product_details_item_image {
  width: 100%;
  height: auto;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 15px;
  overflow: hidden;
}
@media (max-width: 480px) {
  .product_details .product_details_container .product_details_wrapper .product_details_left .product_details_image_wrapper .product_details_image_sidebar .product_details_item_image {
    height: 50px;
  }
}
.product_details .product_details_container .product_details_wrapper .product_details_left .product_details_image_wrapper .product_details_image_sidebar .product_details_item_image .product_details_highlighted_item_image {
  height: 100%;
  width: 100%;
  -o-object-position: center, center;
     object-position: center, center;
  -o-object-fit: contain;
     object-fit: contain;
}
.product_details .product_details_container .product_details_wrapper .product_details_left .product_details_image_wrapper .product_details_image_highlight {
  position: relative;
  flex: 1;
}
@media (max-width: 480px) {
  .product_details .product_details_container .product_details_wrapper .product_details_left .product_details_image_wrapper .product_details_image_highlight {
    width: 100%;
    margin-bottom: 20px;
  }
}
.product_details .product_details_container .product_details_wrapper .product_details_left .product_details_image_wrapper .product_details_image_highlight .product_details_highlighted_image {
  cursor: crosshair;
}
.product_details .product_details_container .product_details_wrapper .product_details_left .product_details_image_wrapper .product_details_image_highlight .product_highlight_cursor {
  position: absolute;
  border: 1px solid #0088f0;
  cursor: crosshair;
  pointer-events: none;
}
.product_details .product_details_container .product_details_wrapper .product_details_left .product_details_image_wrapper .product_details_image_highlight .product_highlight_zoom_canvas {
  position: absolute;
  top: 10px;
  right: 10px;
  pointer-events: none;
  z-index: 1003;
  opacity: 1 !important;
  width: 300px;
  height: 150px;
}
.product_details .product_details_container .product_details_wrapper .product_details_left .product_details_image_wrapper .product_details_image_highlight .product_highlight_meta {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product_details .product_details_container .product_details_wrapper .product_details_left .product_details_image_wrapper .product_details_image_highlight .product_highlight_meta .product_highlight_meta_left {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.product_details .product_details_container .product_details_wrapper .product_details_left .product_details_image_wrapper .product_details_image_highlight .product_highlight_meta .product_highlight_meta_left .likeheart {
  fill: #5c00e6;
  height: 25px;
  width: 25px;
  cursor: pointer !important;
}
.product_details .product_details_container .product_details_wrapper .product_details_left .product_details_image_wrapper .product_details_image_highlight .product_highlight_meta .product_highlight_meta_left span {
  font-size: 0.9rem;
}
.product_details .product_details_container .product_details_wrapper .product_details_left .product_details_image_wrapper .product_details_image_highlight .product_highlight_meta .product_highlight_meta_right .bookmark_product {
  fill: #5c00e6;
  height: 25px;
  width: 25px;
  cursor: pointer !important;
}
.product_details .product_details_container .product_details_wrapper .product_details_left_Sticky {
  position: sticky;
  top: 80px;
  transition: 0.3s;
}
.product_details .product_details_container .product_details_wrapper .product_details_right {
  width: 60%;
  padding: 0 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 480px) {
  .product_details .product_details_container .product_details_wrapper .product_details_right {
    width: 100%;
    flex-direction: column-reverse;
  }
}
.product_details .product_details_container .product_details_wrapper .product_details_right .product_details_r_left {
  flex: 1;
  padding-left: 20px;
}
@media (max-width: 480px) {
  .product_details .product_details_container .product_details_wrapper .product_details_right .product_details_r_left {
    padding-left: 0;
    width: 100%;
    margin-top: 20px;
  }
}
.product_details .product_details_container .product_details_wrapper .product_details_right .product_details_r_left .product_details_r_left_top .product_details_title {
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 20px;
}
@media (max-width: 480px) {
  .product_details .product_details_container .product_details_wrapper .product_details_right .product_details_r_left .product_details_r_left_top .product_details_title {
    font-size: 1rem;
  }
}
.product_details .product_details_container .product_details_wrapper .product_details_right .product_details_r_left .product_details_r_left_top .product_details_title:hover {
  color: #5c00e6;
}
.product_details .product_details_container .product_details_wrapper .product_details_right .product_details_r_left .product_details_r_left_middle {
  margin-bottom: 20px;
}
.product_details .product_details_container .product_details_wrapper .product_details_right .product_details_r_left .product_details_r_left_middle .product_details_r_middle_prices_wrap {
  display: flex;
  margin-bottom: 15px;
}
.product_details .product_details_container .product_details_wrapper .product_details_right .product_details_r_left .product_details_r_left_middle .product_details_r_middle_prices_wrap .product_details_r_middle_price_t {
  margin-right: 20px;
}
.product_details .product_details_container .product_details_wrapper .product_details_right .product_details_r_left .product_details_r_left_middle .product_details_r_middle_prices_wrap .product_details_r_middle_price_t .product_details_r_middle_price {
  font-size: 1.5rem;
}
.product_details .product_details_container .product_details_wrapper .product_details_right .product_details_r_left .product_details_r_left_middle .product_details_r_middle_prices_wrap .product_details_r_middle_price_t .product_details_r_left_middle_discount {
  text-decoration: line-through;
  font-size: 1rem;
}
.product_details .product_details_container .product_details_wrapper .product_details_right .product_details_r_left .product_details_r_left_middle .product_details_r_middle_prices_wrap .product_details_r_left_middle_discount_percentage .percentage_discount {
  font-size: 2rem;
  font-weight: 600;
  color: #5c00e6;
}
.product_details .product_details_container .product_details_wrapper .product_details_right .product_details_r_left .product_details_r_left_middle .product_details_r_middle_actions {
  display: flex;
  gap: 1rem;
  align-items: center;
  align-content: center;
  margin: 1rem 0;
}
.product_details .product_details_container .product_details_wrapper .product_details_right .product_details_r_left .product_details_r_left_middle .product_details_r_middle_actions .product_details_r_middle_action_btn {
  all: unset;
  width: -moz-max-content;
  width: max-content;
  padding: 10px 30px;
  background-color: #fff;
  background: linear-gradient(to bottom right, #14a9ff, #0088f0) !important;
  border-radius: 43px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
}
@media (max-width: 480px) {
  .product_details .product_details_container .product_details_wrapper .product_details_right .product_details_r_left .product_details_r_left_middle .product_details_r_middle_actions .product_details_r_middle_action_btn {
    margin-bottom: 0;
  }
}
.product_details .product_details_container .product_details_wrapper .product_details_right .product_details_r_left .product_details_r_left_middle .product_details_r_middle_actions .product_details_r_middle_action_btn:nth-child(2) {
  background: linear-gradient(to bottom right, #9651ff, #5c00e6) !important;
  margin-bottom: 0;
}
@media (max-width: 480px) {
  .product_details .product_details_container .product_details_wrapper .product_details_right .product_details_r_left .product_details_r_left_middle .product_details_r_middle_actions .product_details_r_middle_action_btn:nth-child(2) {
    margin-left: 20px;
  }
}
@media (max-width: 480px) {
  .product_details .product_details_container .product_details_wrapper .product_details_right .product_details_r_left .product_details_r_left_middle .product_details_r_middle_actions .product_details_r_middle_action_btn {
    margin-right: unset !important;
  }
}
.product_details .product_details_container .product_details_wrapper .product_details_right .product_details_r_left .product_details_r_left_middle .product_details_r_middle_shipping span {
  font-weight: 800;
  color: #5c00e6;
}
.product_details .product_details_container .product_details_wrapper .product_details_right .product_details_r_right {
  width: 0%;
}
.product_details .product_details_container .product_details_r_left_bottom {
  padding: 1rem 0;
  margin-bottom: 20px;
  z-index: 1;
}
.product_details .product_details_container .product_details_r_left_bottom .text {
  transition: height 0.5s ease;
}
.product_details .product_details_container .product_details_r_left_bottom .text.full-text {
  max-height: -moz-min-content !important;
  max-height: min-content !important;
}
.product_details .product_details_container .product_informatics_drawer {
  padding-top: 3rem;
}
.product_details .product_details_container .product_informatics_drawer .prod_info_drawer_header {
  border-bottom: 1px solid #000;
  display: flex;
  align-items: center;
  gap: 10px;
}
.product_details .product_details_container .product_informatics_drawer .prod_info_drawer_header .prod_drawer_item {
  padding: 10px;
  cursor: pointer;
}
.product_details .product_details_container .product_informatics_drawer .prod_info_drawer_header .prod_drawer_item h3 {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.product_details .product_details_container .product_informatics_drawer .prod_info_drawer_header .prod_drawer_item_active {
  border-bottom: 2px solid #000;
}/*# sourceMappingURL=productdetails.css.map */