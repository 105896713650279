.maintainance {
  height: calc(100vh - 70px);
}
.maintainance .maintcontainer {
  position: relative;
  height: 100%;
}
.maintainance .maintcontainer .maintwrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.maintainance .maintcontainer .maintwrap .madmaintimage img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.maintainance .maintcontainer .maintwrap .madmainttexts {
  text-align: center;
  margin-top: 20px;
}
.maintainance .maintcontainer .maintwrap .madmainttexts h2 {
  text-transform: uppercase;
  font-size: 2rem;
}
.maintainance .maintcontainer .maintwrap .madmainttexts p {
  margin-top: 10px;
}/*# sourceMappingURL=maintainance.css.map */