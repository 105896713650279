@import url("https://fonts.googleapis.com/css2?family=Itim&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap");
@font-face {
  font-family: gibsonsemibold;
  src: url("../../assets/fonts/gibson-semibold/Gibson-SemiBold.otf") format("opentype");
}
:root {
  --text-opacity: 1;
  --text-color: #000;
}

* {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  display: inline-block;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}

table {
  margin: 20px 0;
}

table,
th,
tr,
td {
  border: 1px solid #000;
  border-collapse: collapse;
}

td {
  padding: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.hide {
  display: none !important;
}

html,
body {
  scroll-behavior: smooth;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  direction: ltr;
  background: url("../../assets/images/asfalt-dark.png"), linear-gradient(to right top, #d5faff, #d5faff);
}

.cookieConsent {
  width: 190px;
  background: url("../../assets/images/cookie-bg.png") center center/100% 100% no-repeat;
  position: fixed;
  right: 0px;
  padding: 14px 64px 20px 20px;
  z-index: 98;
  left: 16px;
  bottom: 16px !important;
}

.cookieText {
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 15px;
  color: rgb(61, 61, 61);
  letter-spacing: 0.1px;
}

.cookiehiglight {
  font-style: italic;
  font-weight: 700;
}

.privacyPolicy {
  text-decoration: underline;
}

.button {
  font-size: 14px;
  line-height: 15px;
  padding: 9px 22px 10px;
  border: 1px solid rgb(0, 0, 0);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.5px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
  background: transparent;
  transition: color 0.15s ease-in-out 0s;
  display: inline-block;
}

.swiper-pagination-bullet {
  background-color: #c2c2ff !important;
}

/**
 * ==============================================
 * dot-flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  margin: 0 auto;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #ffffff;
  }
  50%, 100% {
    background-color: rgba(255, 243, 243, 0.2);
  }
}
:root {
  --swiper-theme-color: rgb(255, 115, 0);
}

.hide {
  display: none;
}

/**
 * ==============================================
 * dot-flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  margin: 0 auto;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #ffffff;
  }
  50%, 100% {
    background-color: rgba(255, 243, 243, 0.2);
  }
}
.spinner {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 10px solid #ccc;
  border-top-color: #007bff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}/*# sourceMappingURL=index.css.map */