.shop {
  height: 100vh;
}
.shop .shopwrapper {
  margin: 0 5%;
}
.shop .shopwrapper .shopLayout {
  display: flex;
  gap: 2rem;
}
.shop .shopwrapper .shopLayout .shopLeft {
  width: 200px;
  padding-top: 50px;
}
.shop .shopwrapper .shopLayout .shopLeft .shopLeftNav .shprsidenavlink {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 15px 20px;
  border-radius: 35px;
  margin: 10px 0;
  transition: 0.3s;
}
.shop .shopwrapper .shopLayout .shopLeft .shopLeftNav .shprsidenavlink:hover {
  background-color: rgba(39, 39, 39, 0.5411764706);
}
.shop .shopwrapper .shopLayout .shopLeft .shopLeftNav .shprsidenavlink .nvlnkshop {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}
.shop .shopwrapper .shopLayout .shopRight {
  flex: 1;
}
.shop .shopwrapper .shopLayout .shopRight .shopRTop .shopCoverImage {
  height: 200px;
  width: 100%;
  position: relative;
  z-index: 5;
}
.shop .shopwrapper .shopLayout .shopRight .shopRTop .shopCoverImage img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.shop .shopwrapper .shopLayout .shopRight .shopRTop .shopProfileImage {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: -100px;
  margin-left: 40px;
  border: 10px solid #d5faff;
  position: relative;
  z-index: 6;
}
.shop .shopwrapper .shopLayout .shopRight .shopRTop .shopProfileImage img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.shop .shopwrapper .shopLayout .shopRight .shopBreadCrumb {
  display: flex;
  justify-content: space-between;
}
.shop .shopwrapper .shopLayout .shopRight .shopBreadCrumb .crumbRight .edit_shop_profile {
  background: linear-gradient(to bottom right, #14a9ff, #0088f0) !important;
  border-radius: 23px;
  color: #fff !important;
  font-size: 1.2rem;
  line-height: 1.4rem;
  padding: 10px 20px;
  text-align: center;
  transition: 0.4s;
  border: none;
  outline: none;
  margin-top: -100px !important;
  cursor: pointer;
}/*# sourceMappingURL=shop.css.map */