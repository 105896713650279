.showcaseLoader {
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.showcaseLoader .showcasecontloading {
  width: calc(20.15% - 1rem);
}
@media (max-width: 768px) {
  .showcaseLoader .showcasecontloading {
    width: calc(50% - 0.5rem);
  }
}
@media screen and (max-width: 1200px) and (min-width: 769px) {
  .showcaseLoader .showcasecontloading {
    width: calc(33.33% - 1rem);
  }
}
.showcaseLoader .showcasecontloading .c-skeleton-square {
  height: 200px;
  width: 100%;
  border-radius: 10px;
}
.showcaseLoader .showcasecontloading .c-item__bottom {
  display: flex;
  align-items: center;
  margin-top: 7px;
}
.showcaseLoader .showcasecontloading .c-item__bottom .c-skeleton-circle {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.showcaseLoader .showcasecontloading .c-item__bottom .c-skeleton-line {
  flex: 1;
  height: 20px;
  border-radius: 10px;
}

@keyframes loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}
.c-skeleton-line,
.c-skeleton-circle,
.c-skeleton-square {
  background-color: #c2c2ff;
  display: inline-flex;
  line-height: 1;
  position: relative;
  overflow: hidden;
  z-index: 1;
  opacity: 0.9;
}

.c-skeleton-line:after,
.c-skeleton-circle:after,
.c-skeleton-square:after {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, #c2c2ff, #d2d2ff, #c2c2ff);
  transform: translateX(-100%);
  animation-name: loading-skeleton;
  animation-direction: normal;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}/*# sourceMappingURL=showcaseloader.css.map */