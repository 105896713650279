.showcase {
  .showcasewrapper {
    .showcase_prods_fetch_err {
      .showcase_err_top_fetch {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        .showcaer_top_err_left {
          display: flex;
          flex-direction: row;
          align-items: center;
          .showcase_server_ic {
            width: 200px;
            height: 100px;
          }
          .showcase_server_status {
            font-size: 10rem;
            user-select: none;
          }
        }
        .showcaer_top_err_right {
          .showcase_retry {
            all: unset;
            padding: 10px 30px;
            background-color: #fff;
            background: linear-gradient(
              to bottom right,
              #14a9ff,
              #0088f0
            ) !important;
            border-radius: 43px;
            text-align: center;
            color: #fff;
            cursor: pointer;
            margin-right: 15px;
            @media (max-width: 480px) {
              margin-right: unset !important;
            }
          }
        }
      }
      .showcase_err_message {
        text-align: center;
      }
    }
  }
}
