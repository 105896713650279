.cart {
  height: -moz-min-content;
  height: min-content;
  padding-bottom: 20px;
}
.cart .cartcontainer {
  margin: 0 5%;
}
.cart .cartcontainer .checkoutmodal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.6509803922);
}
.cart .cartcontainer .checkoutmodal .cartcheckoutinfobox,
.cart .cartcontainer .checkoutmodal .cartcheckouterrbox {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  z-index: 1001;
  width: 400px;
  height: 300px;
  background-color: #008f39;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
}
@media (max-width: 480px) {
  .cart .cartcontainer .checkoutmodal .cartcheckoutinfobox,
  .cart .cartcontainer .checkoutmodal .cartcheckouterrbox {
    width: 80%;
  }
}
.cart .cartcontainer .checkoutmodal .cartcheckoutinfobox P,
.cart .cartcontainer .checkoutmodal .cartcheckouterrbox P {
  font-size: 1.5rem;
  text-align: center;
  position: relative;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -60%);
  margin-top: 20px;
}
.cart .cartcontainer .checkoutmodal .cartcheckoutinfobox .loader,
.cart .cartcontainer .checkoutmodal .cartcheckouterrbox .loader {
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}
@keyframes mulShdSpin {
  0%, 100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
.cart .cartcontainer .checkoutmodal .cartcheckouterrbox {
  background-color: #ff4f4f !important;
}
.cart .cartcontainer .cartwrapper {
  position: relative;
}
.cart .cartcontainer .cartwrapper .emptycart {
  height: 600px;
  width: 100%;
  position: relative;
}
.cart .cartcontainer .cartwrapper .emptycart .crtcentercont {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cart .cartcontainer .cartwrapper .emptycart .crtcentercont .carticon {
  height: 200px;
  width: 200px;
  margin: 0 auto;
  margin-left: 20px;
  fill: #1b1b1b;
}
.cart .cartcontainer .cartwrapper .emptycart .crtcentercont p {
  text-align: center;
  font-weight: 800;
  font-size: 1.3rem;
  text-transform: capitalize;
  margin-left: -10px;
}
.cart .cartcontainer .cartwrapper .emptycart .crtcentercont .linktoallproducts .startbrowsing {
  outline: none;
  border: none;
  padding: 20px 40px;
  text-align: center;
  font-weight: 800;
  font-size: 1rem;
  text-transform: uppercase;
  display: block;
  margin: 0 auto;
  margin-top: 1.5rem;
  background-color: #5a8eff;
  color: #fff;
  cursor: pointer;
  margin-bottom: 0px;
  border-radius: 5px;
  box-shadow: 0 4px 11px 0 rgba(37, 44, 97, 0.15), 0 1px 3px 0 rgba(93, 100, 148, 0.2);
}
.cart .cartcontainer .cartwrapper .emptycart .crtcentercont .linktoallproducts .startbrowsing:hover {
  transform: scale(1.07);
}
.cart .cartcontainer .cartwrapper .mycartcont {
  padding-top: 20px;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 2rem;
}
@media (max-width: 480px) {
  .cart .cartcontainer .cartwrapper .mycartcont .mycartlayout {
    flex-direction: column;
  }
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft {
  flex: 1;
}
@media (max-width: 480px) {
  .cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft {
    width: 100%;
  }
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitle {
  background-color: #fff;
  padding: 10px;
  border: 1px solid #000;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 4px 5px, rgba(0, 0, 0, 0.1) 0px 1px 10px;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitle h2 {
  text-transform: capitalize;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems {
  position: relative;
  overflow: hidden;
  display: flex;
  gap: 1rem;
  margin-top: 20px;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #000;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 4px 5px, rgba(0, 0, 0, 0.1) 0px 1px 10px;
}
@media (max-width: 480px) {
  .cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .cartitempicture {
  height: 200px;
  min-width: 300px;
}
@media (max-width: 480px) {
  .cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .cartitempicture {
    width: 100px !important;
  }
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .cartitempicture img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 480px) {
  .cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemdetails {
    grid-column: span 3;
  }
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemdetails h2 {
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  line-height: 1.3;
  text-overflow: ellipsis;
}
@media (max-width: 480px) {
  .cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemdetails h2 {
    font-size: 0.9rem;
  }
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemdetails p {
  margin: 10px 0;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemdetails .cartitemquantity {
  display: flex;
  align-items: center;
  border: 1px solid #000;
  width: -moz-max-content;
  width: max-content;
  margin-top: 20px;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemdetails .cartitemquantity .decrement {
  border-right: 1px solid #000;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemdetails .cartitemquantity .increment {
  border-left: 1px solid #000;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemdetails .cartitemquantity .decrement,
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemdetails .cartitemquantity .increment {
  background-color: #83cdff;
  padding: 10px;
  cursor: pointer;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemdetails .cartitemquantity .decrement span,
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemdetails .cartitemquantity .increment span {
  font-weight: 700;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemdetails .cartitemquantity p {
  margin: 0 15px;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemfarend {
  margin-left: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
@media (max-width: 480px) {
  .cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemfarend {
    overflow: visible;
    width: 100px;
  }
}
@media (max-width: 480px) {
  .cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemfarend {
    margin-left: 0;
  }
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemfarend .mycartitempricing {
  text-align: right;
}
@media (max-width: 480px) {
  .cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemfarend .mycartitempricing {
    text-align: left;
  }
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemfarend .mycartitempricing h2 {
  white-space: nowrap;
  margin-bottom: 15px;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemfarend .mycartitempricing .cartiteminitialprice {
  text-decoration: line-through;
  white-space: nowrap;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemfarend .mycartitempricing .pricingpercentage {
  background-color: #83cdff;
  margin-left: 10px;
  font-size: 0.9rem;
  white-space: nowrap;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemfarend .removecartitem {
  display: flex;
  align-items: center;
  align-self: flex-end;
  padding: 10px;
  cursor: pointer;
}
@media (max-width: 480px) {
  .cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemfarend .removecartitem {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemfarend .removecartitem .trashIcon {
  height: 20px;
  width: 20px;
  fill: red;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartleft .mycartitems .mycartitemfarend .removecartitem p {
  color: red;
  font-weight: 700;
  margin-left: 5px;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartrightsticky {
  position: sticky;
  top: 80px;
  transition: 0.3s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright {
  width: 25%;
  min-width: 350px;
  height: -moz-min-content !important;
  height: min-content !important;
}
@media (max-width: 480px) {
  .cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright {
    width: 100% !important;
    min-width: unset;
  }
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle {
  background-color: #fff;
  padding: 10px;
  border: 1px solid #000;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-bottom: 2rem;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle h2 {
  margin-left: 20px;
  margin-left: 10px;
  text-transform: uppercase;
  white-space: nowrap;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .carttotalpricing {
  padding: 10px;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .carttotalpricing .carttotalpricingflex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .carttotalpricing .carttotalpricingflex h4 {
  font-size: 1.4rem;
  text-transform: capitalize;
  white-space: nowrap;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .carttotalpricing .carttotalpricingflex p {
  font-size: 1.4rem;
  font-weight: 600;
  white-space: nowrap;
  margin: 0;
  margin-top: -10px;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .carttotalpricing .deliveryNotIncluded {
  margin: 5px 0;
  font-size: 0.7rem;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .carttotalpricing h4 {
  margin-bottom: 10px;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .carttotalpricing .selectDelivErr {
  border: 2px solid #f00;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .cartproddelivery {
  padding: 20px 10px;
  padding-bottom: 0;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .cartproddelivery h3 {
  margin-bottom: 10px;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .cartproddelivery h3:nth-child(2) {
  margin-top: 20px;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .cartproddelivery .allprodcatlistfieldset {
  border: none;
  outline: none;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .cartproddelivery .allprodcatlistfieldset .allprodlistitem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .cartproddelivery .allprodcatlistfieldset .allprodlistitem .filterradioselection {
  margin-right: 10px;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .crtchoosepaymentmethod {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 50px;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .crtchoosepaymentmethod .crtchooseOptions {
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .crtchoosepaymentmethod .crtchooseOptions .PaymentOnDelivery {
  display: block;
  height: 25px;
  width: 40px;
  fill: #282828;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .crtchoosepaymentmethod .crtchooseOptions .mpesaIc {
  display: block;
  height: 45px !important;
  width: 45px !important;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .crtchoosepaymentmethod .crtchooseOptions .mastercardIc,
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .crtchoosepaymentmethod .crtchooseOptions .visa {
  width: 40px !important;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .crtchoosepaymentmethod .activepaymentoptionm {
  padding: 0px 20px;
  border: 1px solid #5a8eff;
  background-color: #e0cfff;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .crtchoosepaymentmethod .activepaymentoptionm .mpesaIc {
  display: block;
  height: 35px !important;
  width: 35px !important;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .crtchoosepaymentmethod .activepaymentoption {
  border: 1px solid #5a8eff;
  background-color: #e0cfff;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .mpesamobilenumber {
  width: 100%;
  margin: 20px 0;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .mpesamobilenumber .mobilenumberstk {
  outline: none;
  padding: 10px;
  width: calc(100% - 40px);
  margin: 0 10px;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .mpesamobilenumber span {
  font-size: 0.7rem;
  margin-left: 20px;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .chkmainform .btncheckout {
  outline: none;
  border: none;
  padding: 20px;
  width: 90%;
  font-weight: 800;
  font-size: 1rem;
  text-transform: uppercase;
  display: block;
  margin: 0 auto;
  margin-top: 1rem;
  background-color: #5a8eff;
  color: #fff;
  cursor: pointer;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .chkmainform .btncheckout:hover {
  transform: scale(1.02);
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .chkmainform .btncheckout span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .chkmainform .btncheckout span .chkbtnloader {
  width: 21px;
  height: 21px;
  border: 3px solid #fff;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .ordersummarytitle .chkmainform .btncheckout span .chkbtnloader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 3px solid;
  border-color: #ff3d00 transparent;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cart .cartcontainer .cartwrapper .mycartcont .mycartlayout .mycartright .cartreturnpolicy {
  background-color: #ff0606;
  height: 50px;
  width: 100%;
}/*# sourceMappingURL=cart.css.map */