.maincarouselslider{
    height: 400px;
    background-color: #000000;
    @media (max-width: 480px) {
        height: auto;
        max-height: 300px;
    }
}
.sliderImagePreview{
    border-radius: 5px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 0 0;
    transition: all 200ms;
    -webkit-mask-image: linear-gradient(180deg,#000,transparent);
    mask-image: linear-gradient(180deg,#000,transparent);
}


.swiper-slide {
    height: 100% !important;
}

.swiper-content {
    height: 100% !important;
}