.showcase_content {
  width: 100%;
  margin-bottom: 20px;
}
.showcase_content .showcasecont_header {
  padding: 5px;
  background-color: #1fadff;
  margin-bottom: 5px;
  border-radius: 5px;
}
.showcase_content .showcasecont_header .showcasecont_header_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.showcase_content .showcasecont_header .showcasecont_header_wrapper .showcasecont_header_title {
  font-size: 1.4rem;
  margin-left: 10px;
  color: aliceblue;
}
.showcase_content .showcasecont_header .showcasecont_header_wrapper .showcase_header_see_all {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 10px;
}
.showcase_content .showcasecont_header .showcasecont_header_wrapper .showcase_header_see_all h2 {
  color: aliceblue;
  font-size: 0.8rem;
  text-transform: uppercase;
}
.showcase_content .showcasecont_header .showcasecont_header_wrapper .showcase_header_see_all .showcase_arrow_right {
  height: 15px;
  width: 15px;
  fill: aliceblue;
  transition: 0.4s ease-in-out;
  cursor: pointer;
}
.showcase_content .showcasecont_header .showcasecont_header_wrapper .showcase_header_see_all .showcase_arrow_right:hover {
  transform: translateX(4px);
}/*# sourceMappingURL=showcase.css.map */