:root {
  --cyan-color: rgb(48, 188, 237);
  --mauve-color: rgb(217, 187, 249);
  --pastel-green-color: rgb(32, 191, 85);
  --mustard-color: rgb(255, 212, 71);
}

.showcase {
  margin: 0 5%;
  margin-bottom: 2rem;
  .allproductsinfobox {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    border: 1px solid #00ff55;
    background-color: #009532;
    padding: 5px 10px;
    border-radius: 20px;
    color: #fff;
  }
  .allproductserrbox {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    border: 1px solid #ff0000;
    background-color: #950000;
    padding: 5px 10px;
    border-radius: 20px;
    color: #fff;
  }
  .showcasewrapper {
    .shcasebreadcrumb {
      display: flex;
      margin: 20px 0;
      .shcatbrd,
      .shbrandsbrd {
        width: 30%;
        margin-right: 20px;
      }
    }
    // .showcasecont {
    //   display: flex;
    //   flex-wrap: wrap;
    //   gap: 1rem;
    // }
  }
}

.showcasecont_gridify {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
  @media (max-width: 1366px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 830px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.showcasecont {
  .swiper-button-next,
  .swiper-button-prev {
    margin-top: -55px;
  }
}
