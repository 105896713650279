.cart {
  height: min-content;
  padding-bottom: 20px;
  .cartcontainer {
    margin: 0 5%;
    .checkoutmodal {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100% !important;
      z-index: 1000;
      background-color: #000000a6;
      .cartcheckoutinfobox,
      .cartcheckouterrbox {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -40%);
        z-index: 1001;
        width: 400px;
        height: 300px;
        background-color: #008f39;
        padding: 5px 10px;
        border-radius: 5px;
        color: #fff;
        @media (max-width: 480px) {
          width: 80%;
        }
        P {
          font-size: 1.5rem;
          text-align: center;
          position: relative;
          top: 60%;
          left: 50%;
          transform: translate(-50%, -60%);
          margin-top: 20px;
        }
        .loader {
          display: block;
          font-size: 10px;
          width: 1em;
          height: 1em;
          border-radius: 50%;
          position: relative;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -40%);
          text-indent: -9999em;
          animation: mulShdSpin 1.1s infinite ease;
          transform: translateZ(0);
        }
        @keyframes mulShdSpin {
          0%,
          100% {
            box-shadow: 0em -2.6em 0em 0em #ffffff,
              1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
              2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
              1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
              0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
              -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
              -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
              -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
          }
          12.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
              1.8em -1.8em 0 0em #ffffff,
              2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
              1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
              0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
              -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
              -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
              -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
          }
          25% {
            box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
              1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7),
              2.5em 0em 0 0em #ffffff,
              1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
              0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
              -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
              -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
              -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
          }
          37.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
              1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
              2.5em 0em 0 0em rgba(255, 255, 255, 0.7),
              1.75em 1.75em 0 0em #ffffff,
              0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
              -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
              -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
              -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
          }
          50% {
            box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
              1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
              2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
              1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7),
              0em 2.5em 0 0em #ffffff,
              -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
              -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
              -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
          }
          62.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
              1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
              2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
              1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
              0em 2.5em 0 0em rgba(255, 255, 255, 0.7),
              -1.8em 1.8em 0 0em #ffffff,
              -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
              -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
          }
          75% {
            box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
              1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
              2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
              1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
              0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
              -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7),
              -2.6em 0em 0 0em #ffffff,
              -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
          }
          87.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
              1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
              2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
              1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
              0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
              -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
              -2.6em 0em 0 0em rgba(255, 255, 255, 0.7),
              -1.8em -1.8em 0 0em #ffffff;
          }
        }
      }
      .cartcheckouterrbox {
        background-color: #ff4f4f !important;
      }
    }
    .cartwrapper {
      position: relative;
      .emptycart {
        height: 600px;
        width: 100%;
        position: relative;
        .crtcentercont {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          .carticon {
            height: 200px;
            width: 200px;
            margin: 0 auto;
            margin-left: 20px;
            fill: #1b1b1b;
          }
          p {
            text-align: center;
            font-weight: 800;
            font-size: 1.3rem;
            text-transform: capitalize;
            margin-left: -10px;
          }
          .linktoallproducts {
            .startbrowsing {
              outline: none;
              border: none;
              padding: 20px 40px;
              text-align: center;
              font-weight: 800;
              font-size: 1rem;
              text-transform: uppercase;
              display: block;
              margin: 0 auto;
              margin-top: 1.5rem;
              background-color: #5a8eff;
              color: #fff;
              cursor: pointer;
              margin-bottom: 0px;
              border-radius: 5px;
              box-shadow: 0 4px 11px 0 rgb(37 44 97 / 15%),
                0 1px 3px 0 rgb(93 100 148 / 20%);
              &:hover {
                transform: scale(1.07);
              }
            }
          }
        }
      }
      .mycartcont {
        padding-top: 20px;
        .mycartlayout {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          gap: 2rem;
          @media (max-width: 480px) {
            flex-direction: column;
          }
          .mycartleft {
            flex: 1;
            @media (max-width: 480px) {
              width: 100%;
            }
            .mycartitle {
              background-color: #fff;
              padding: 10px;
              border: 1px solid #000;
              box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 4px,
                rgba(0, 0, 0, 0.06) 0px 4px 5px, rgba(0, 0, 0, 0.1) 0px 1px 10px;
              h2 {
                text-transform: capitalize;
              }
            }
            .mycartitems {
              position: relative;
              overflow: hidden;
              display: flex;
              gap: 1rem;
              margin-top: 20px;
              background-color: #fff;
              padding: 10px;
              border: 1px solid #000;
              box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 4px,
                rgba(0, 0, 0, 0.06) 0px 4px 5px, rgba(0, 0, 0, 0.1) 0px 1px 10px;
              @media (max-width: 480px) {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
              }
              .cartitempicture {
                //height: auto;
                height: 200px;
                min-width: 300px;
                @media (max-width: 480px) {
                  width: 100px !important;
                }
                img {
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                }
              }
              .mycartitemdetails {
                @media (max-width: 480px) {
                  grid-column: span 3;
                }
                h2 {
                  margin-bottom: 10px;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  -webkit-line-clamp: 3;
                  line-height: 1.3;
                  text-overflow: ellipsis;
                  @media (max-width: 480px) {
                    font-size: 0.9rem;
                  }
                }
                p {
                  margin: 10px 0;
                }
                .cartitemquantity {
                  display: flex;
                  align-items: center;
                  border: 1px solid #000;
                  width: max-content;
                  margin-top: 20px;
                  .decrement {
                    border-right: 1px solid #000;
                  }
                  .increment {
                    border-left: 1px solid #000;
                  }
                  .decrement,
                  .increment {
                    background-color: #83cdff;
                    padding: 10px;
                    cursor: pointer;
                    span {
                      font-weight: 700;
                    }
                  }
                  p {
                    margin: 0 15px;
                  }
                }
              }
              .mycartitemfarend {
                @media (max-width: 480px) {
                  overflow: visible;
                  width: 100px;
                }
                margin-left: 3rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex: 1;
                @media (max-width: 480px) {
                  margin-left: 0;
                }
                .mycartitempricing {
                  text-align: right;
                  @media (max-width: 480px) {
                    text-align: left;
                  }
                  h2 {
                    white-space: nowrap;
                    margin-bottom: 15px;
                  }
                  .cartiteminitialprice {
                    text-decoration: line-through;
                    white-space: nowrap;
                  }
                  .pricingpercentage {
                    background-color: #83cdff;
                    margin-left: 10px;
                    font-size: 0.9rem;
                    white-space: nowrap;
                  }
                }
                .removecartitem {
                  display: flex;
                  align-items: center;
                  align-self: flex-end;
                  padding: 10px;
                  cursor: pointer;
                  @media (max-width: 480px) {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                  }
                  .trashIcon {
                    height: 20px;
                    width: 20px;
                    fill: red;
                  }
                  p {
                    color: red;
                    font-weight: 700;
                    margin-left: 5px;
                  }
                }
              }
            }
          }
          .mycartrightsticky {
            position: sticky;
            top: 80px;
            transition: 0.3s;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          }
          .mycartright {
            width: 25%;
            min-width: 350px;

            height: min-content !important;
            @media (max-width: 480px) {
              width: 100% !important;
              min-width: unset;
            }
            .ordersummarytitle {
              background-color: #fff;
              padding: 10px;
              border: 1px solid #000;
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
              margin-bottom: 2rem;
              h2 {
                margin-left: 20px;
                margin-left: 10px;
                text-transform: uppercase;
                white-space: nowrap;
              }
              .carttotalpricing {
                padding: 10px;
                .carttotalpricingflex {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  h4 {
                    font-size: 1.4rem;
                    text-transform: capitalize;
                    white-space: nowrap;
                  }
                  p {
                    font-size: 1.4rem;
                    font-weight: 600;
                    white-space: nowrap;
                    margin: 0;
                    margin-top: -10px;
                  }
                }
                .deliveryNotIncluded {
                  margin: 5px 0;
                  font-size: 0.7rem;
                }
                h4 {
                  margin-bottom: 10px;
                }
                .selectDelivErr {
                  border: 2px solid #f00;
                }
              }
              .cartproddelivery {
                padding: 20px 10px;

                padding-bottom: 0;
                h3 {
                  margin-bottom: 10px;
                  &:nth-child(2) {
                    margin-top: 20px;
                  }
                }
                .allprodcatlistfieldset {
                  border: none;
                  outline: none;
                  .allprodlistitem {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    .filterradioselection {
                      margin-right: 10px;
                    }
                  }
                }
              }
              .crtchoosepaymentmethod {
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                height: 50px;
                .crtchooseOptions {
                  // margin-right: 20px;
                  padding: 5px 20px;
                  border-radius: 5px;
                  cursor: pointer;
                  .PaymentOnDelivery {
                    display: block;
                    height: 25px;
                    width: 40px;
                    fill: #282828;
                  }
                  .mpesaIc {
                    display: block;
                    height: 45px !important;
                    width: 45px !important;
                  }
                  .mastercardIc,
                  .visa {
                    width: 40px !important;
                  }
                }
                .activepaymentoptionm {
                  padding: 0px 20px;
                  border: 1px solid #5a8eff;
                  background-color: #e0cfff;
                  .mpesaIc {
                    display: block;
                    height: 35px !important;
                    width: 35px !important;
                  }
                }
                .activepaymentoption {
                  border: 1px solid #5a8eff;
                  background-color: #e0cfff;
                }
              }
              .mpesamobilenumber {
                width: 100%;
                margin: 20px 0;
                .mobilenumberstk {
                  outline: none;
                  padding: 10px;
                  width: calc(100% - 40px);
                  margin: 0 10px;
                }
                span {
                  font-size: 0.7rem;
                  margin-left: 20px;
                }
              }
              .chkmainform {
                .btncheckout {
                  outline: none;
                  border: none;
                  padding: 20px;
                  width: 90%;
                  font-weight: 800;
                  font-size: 1rem;
                  text-transform: uppercase;
                  display: block;
                  margin: 0 auto;
                  margin-top: 1rem;
                  background-color: #5a8eff;
                  color: #fff;
                  cursor: pointer;
                  margin-bottom: 20px;
                  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                  overflow: hidden;
                  border-radius: 5px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  &:hover {
                    transform: scale(1.02);
                  }
                  span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 1rem;
                    .chkbtnloader {
                      width: 21px;
                      height: 21px;
                      border: 3px solid #fff;
                      border-radius: 50%;
                      display: inline-block;
                      position: relative;
                      box-sizing: border-box;
                      animation: rotation 1s linear infinite;
                      &::after {
                        content: '';
                        box-sizing: border-box;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        border: 3px solid;
                        border-color: #ff3d00 transparent;
                      }
                    }
                    @keyframes rotation {
                      0% {
                        transform: rotate(0deg);
                      }
                      100% {
                        transform: rotate(360deg);
                      }
                    }
                  }
                }
              }
            }
            .cartreturnpolicy {
              background-color: #ff0606;
              height: 50px;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
