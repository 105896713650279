.shop{
    height: 100vh;
    .shopwrapper{
        margin: 0 5%;
        .shopLayout{
            display: flex;
            gap: 2rem;
            .shopLeft{
                width: 200px;
                padding-top: 50px;
                .shopLeftNav{
                    .shprsidenavlink{
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        padding: 15px 20px;
                        border-radius: 35px;
                        margin: 10px 0;
                        transition: .3s;
                        &:hover{
                            background-color: #2727278a;
                        }
                        .nvlnkshop{
                            height: 25px;
                            width: 25px;
                            margin-right: 10px;
                        }
                    }
                }
            }
            .shopRight{
                flex: 1;
                .shopRTop{
                    .shopCoverImage{
                        height: 200px;
                        width: 100%;
                        position: relative;
                        z-index: 5;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .shopProfileImage{
                        height: 200px;
                        width: 200px;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-top: -100px;
                        margin-left: 40px;
                        border: 10px solid #d5faff;
                        position: relative;
                        z-index: 6;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
                .shopBreadCrumb{
                    display: flex;
                    justify-content: space-between;
                    .crumbLeft{
                        h1{

                        }
                        P{

                        }
                    }
                    .crumbRight{
                        .edit_shop_profile{
                            background: linear-gradient(to bottom right,#14a9ff,#0088f0)!important;
                            border-radius: 23px;
                            color: #fff!important;
                            font-size: 1.2rem;
                            line-height: 1.4rem;
                            padding: 10px 20px;
                            text-align: center;
                            transition: .4s;
                            border: none;
                            outline: none;
                            margin-top: -100px !important;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}