.mbsidebar {
  height: 100vh;
  width: 0;
  background-color: #fff;
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 999;
  overflow: hidden !important;
  transition: width 0.5s ease;
}
.mbsidebar .mbsidebar_container {
  position: relative;
  width: 100%;
}
.mbsidebar .mbsidebar_container .mbisidebar_close {
  position: absolute;
  top: 10px;
  right: 20px;
  height: 25px;
  width: 25px;
}
.mbsidebar .mbsidebar_container .mobi_header_sidebar {
  margin: 10px 20px;
}
.mbsidebar .mbsidebar_container .mobi_header_sidebar .mbi_sdbar_left .mbi_sdbar_logo {
  height: 40px;
  width: 40px;
}
.mbsidebar .mbsidebar_container .mbsidetop .closemobisidebar {
  height: 30px;
  width: 30px;
}
.mbsidebar .mbsidebar_container .mbnavlinks .mbnavlink {
  margin: 20px;
}
.mbsidebar .mbsidebar_container .mbnavlinks .mbnavlink .mblinkwrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mbsidebar .mbsidebar_container .mbnavlinks .mbnavlink .mblinkwrapper p {
  font-size: 1.3rem;
  white-space: nowrap;
}
.mbsidebar .mbsidebar_container .mbnavlinks .mbnavlink .mblinkwrapper .mbsideangledown {
  height: 20px;
  width: 20px;
}
.mbsidebar .mbsidebar_container .mbnavlinks .mbnavlink .mblinkwrapper .mbsideangledown_rotate {
  transform: rotate(-90deg);
}
.mbsidebar .mbsidebar_container .mbnavlinks .mbnavlink .mblinkchildren {
  height: -moz-min-content;
  height: min-content;
  margin-left: 30px;
  transition: height 0.3s ease-in-out;
}
.mbsidebar .mbsidebar_container .mbnavlinks .mbnavlink .mblinkchildren .mblinknavchildren .childmblink {
  margin: 10px 0;
  white-space: nowrap;
}
.mbsidebar .mbsidebar_container .mbnavlinks .mbnavlink .mblinkchildren .mblinknavchildren .childmblink a p {
  white-space: nowrap;
}

.showmbsidebar {
  width: 100%;
}/*# sourceMappingURL=mobilesidebar.css.map */