.meta_selector_parent {
  .meta_selector {
    margin-right: 10px;
    cursor: pointer;
    .meta_selector_container {
      .meta_selector_wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5px;
        .meta_selector_inner_wrapper {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .meta_selector_flag {
            height: 15px;
            width: 20px;
            overflow: hidden;
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
          span {
            font-size: 0.7rem;
            text-align: center;
            margin-right: -3px;
            margin-top: 2px;
          }
        }
        .meta_selector_right {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 2px;
          .meta_selector_iso_name {
            span {
              font-weight: 500;
              margin: 0;
              user-select: none;
            }
          }
          .meta_selector_angle_down {
            height: 10px;
            width: 10px;
            fill: #fff;
            margin-bottom: -3px;
          }
        }
      }
      .meta_selector_drop_down_parent {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
        height: 100vh;
        background-color: #0c0c0c70;
        .meta_selector_drop_down {
          position: absolute;
          top: 65px;
          right: 8%;
          z-index: 999;
          height: 500px;
          width: 300px;
          background-color: #fff;
          border: 1px solid #000;
          box-shadow: #00000059 0px 5px 15px;
          //   overflow-y: scroll;
          // &::before {
          //   content: '';
          //   display: block;
          //   width: 0;
          //   height: 0;
          //   border-left: 20px solid transparent;
          //   border-right: 20px solid transparent;
          //   border-bottom: 20px solid #ffffff;
          //   margin: 0 auto;
          //   margin-top: -20px;
          // }
          .meta_selector_ordered_list_countries {
            height: 50%;
            overflow-y: scroll;
            position: relative;
            .meta_selector_ordered_list_items {
              padding: 0 10px;
              display: flex;
              align-items: center;
              gap: 20px;
              margin: 5px 0;
              overflow: hidden;
              &:hover {
                background-color: #c3c3c3;
              }
              .meta_selector_country_name {
                color: #000;
                white-space: none;
                user-select: none;
                @media (max-width: 830px) {
                  display: block !important;
                }
              }
            }
          }
          .meta_selector_ordered_list_currency {
            height: 50%;
            border-top: 1px solid black;
            overflow-y: scroll;
            position: relative;
            .meta_selector_ordered_list_items {
              padding: 0 10px;
              display: flex;
              align-items: center;
              margin: 5px 0;
              overflow: hidden;
              justify-content: left;
              &:hover {
                background-color: #c3c3c3;
              }
              .meta_selector_symbol {
                width: min-content;
                text-align: center;
                @media (max-width: 830px) {
                  display: block !important;
                }
                span {
                  color: #000;
                  user-select: none;
                  @media (max-width: 830px) {
                    display: block !important;
                  }
                }
              }
              span {
                @media (max-width: 830px) {
                  display: block !important;
                }
              }
              .meta_selector_currency_name {
                color: #000;
                user-select: none;
                @media (max-width: 830px) {
                  display: block !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
.meta_selector_filter_options {
  width: calc(100% - 10px);
  padding: 7px 5px;
  position: sticky;
  top: 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  outline: none;
  border: none;
}
