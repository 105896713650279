.auth {
  height: 100vh;
}
.auth .auth_container {
  position: relative;
  height: 100%;
}
.auth .auth_container .auth_wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 55%;
  height: 75vh;
  box-shadow: rgba(0, 0, 0, 0.3490196078) 0px 5px 15px;
  border-radius: 8px;
  border-top: 2px solid #5a8eff;
  overflow: hidden;
}
.auth .auth_container .auth_wrapper .auth_left {
  width: 50%;
  position: relative;
  height: 100%;
  background-color: #ffffff;
  background-image: linear-gradient(180deg, #fff, #dfdfff);
}
.auth .auth_container .auth_wrapper .auth_left .auth_login_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  padding: 5px;
}
.auth .auth_container .auth_wrapper .auth_left .auth_login_box h1 {
  margin: 1.2rem 0;
  color: rgba(72, 76, 122, var(--text-opacity));
}
.auth .auth_container .auth_wrapper .auth_left .auth_login_box .auth_login_form .auth_form_group {
  margin-bottom: 1.2rem;
}
.auth .auth_container .auth_wrapper .auth_left .auth_login_box .auth_login_form .auth_form_group .auth_label {
  font-size: 0.8rem;
}
.auth .auth_container .auth_wrapper .auth_left .auth_login_box .auth_login_form .auth_form_group .auth_label span {
  color: red;
}
.auth .auth_container .auth_wrapper .auth_left .auth_login_box .auth_login_form .auth_form_group .auth_login_input {
  padding: 10px;
  margin-top: 3px;
  border: 1px solid #2c2c2c;
  border-radius: 5px;
  outline: none;
  width: calc(100% - 20px);
}
.auth .auth_container .auth_wrapper .auth_left .auth_login_box .auth_login_form .auth_info {
  text-align: center;
}
.auth .auth_container .auth_wrapper .auth_left .auth_login_box .auth_login_form .auth_info span {
  color: #167bff;
  cursor: pointer;
}
.auth .auth_container .auth_wrapper .auth_left .auth_login_box .auth_login_form .auth_info span:hover {
  text-decoration: underline;
}
.auth .auth_container .auth_wrapper .auth_left .auth_login_box .auth_login_form .horizontal_rule_container {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}
.auth .auth_container .auth_wrapper .auth_left .auth_login_box .auth_login_form .horizontal_rule_container .horizontal_rule {
  flex: 1;
  height: 1px;
  background-color: #595959;
  margin: 0 10px;
}
.auth .auth_container .auth_wrapper .auth_left .auth_login_box .auth_login_form .horizontal_rule_container .or_text {
  font-weight: 500;
  margin-top: -4px;
}
.auth .auth_container .auth_wrapper .auth_left .auth_login_box .auth_login_form .auth_btn_submit {
  padding: 10px 0;
  width: 100%;
  outline: none;
  border: none;
  background-color: #167bff;
  color: #fff;
  font-size: 1rem;
  margin: 1rem auto;
  margin-top: 0;
  border-radius: 5px;
  cursor: pointer;
}
.auth .auth_container .auth_wrapper .auth_left .auth_login_box .auth_login_form .auth_other_login_options {
  margin: 1rem auto;
}
.auth .auth_container .auth_wrapper .auth_right {
  width: 50%;
  height: 100%;
  position: relative;
}
.auth .auth_container .auth_wrapper .auth_right .auth_intro_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.auth .auth_container .auth_wrapper .auth_right .auth_right_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.auth .auth_container .auth_wrapper .auth_right .auth_right_wrapper .auth_home_logo_link {
  font-family: "Itim", cursive;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  white-space: nowrap;
}/*# sourceMappingURL=auth.css.map */