.checkout {
  .checkout_container {
    margin: 0 5%;
    .payment_processing_md_p {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #000000ac;
      .payment_processing_md_p_wrap {
        height: 300px;
        width: 400px;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border-radius: 5px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .close_payment_processing {
          height: 20px;
          width: 20px;
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
        }
        .pr_center_md_p {
          p {
            text-align: center;
          }
          .pr_center_md_p_spinner {
            margin: 20px auto;
          }
        }
      }
    }
    .checkout_wrapper {
      width: 100%;
      padding: 1.3rem 0;
      .checkout_flexxer {
        display: flex;
        justify-content: center;
        gap: 10px;
        border-radius: 5px;
        width: 100%;
        padding: 2px;
        @media (max-width: 830px) {
          flex-direction: column;
        }
        .checkout_cart_summary {
          position: relative;
          flex: 1;
          background-color: #fff;
          border-radius: 5px;
          padding: 5px;
          padding-top: 10px;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          @media (max-width: 830px) {
            width: calc(100% - 10px);
          }
          h1 {
            margin: 10px 0 20px 20px;
            font-size: 1rem;
            height: 10px;
          }
          .print_cart_summary_checkout {
            position: absolute;
            top: 10px;
            right: 20px;
            height: 20px;
            width: 20px;
            cursor: pointer;
          }
          .checkout_cart_summary_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: calc(100% - 40px);
            .checkout_cart_summary_unordered {
              .checkout_cart_summary_item {
                border-bottom: 1px solid #000;
                padding: 20px 10px 20px 10px;
                cursor: pointer;
                transition: 0.5s ease-in-out;
                &:hover {
                  background-color: #02020236;
                }
                .checkout_cart_summary_item_wrapper {
                  display: flex;
                  .checkout_cart_summary_ic {
                    cursor: pointer;
                    height: 15px;
                    width: 15px;
                    margin: 0 20px 10px 20px;
                    transition: 0.5s;
                  }
                  .checkout_cart_summary_info {
                    flex: 1;
                    .checkout_cart_summary_inner_wrapper {
                      display: flex;
                      align-items: flex-start;
                      justify-content: space-between;
                      gap: 10px;
                      .summarized_product_name {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        -webkit-line-clamp: 3;
                        line-height: 1.3;
                        text-overflow: ellipsis;
                      }
                      p {
                        user-select: none;
                        &:nth-child(1) {
                          max-width: 600px;
                        }
                        &:nth-child(2),
                        &:nth-child(3) {
                          white-space: nowrap;
                          font-size: 1.4rem;
                          font-weight: 600;
                        }
                      }
                    }
                    .checkout_summary_compact_details {
                      user-select: none;
                    }
                  }
                }
              }
            }
            .checkout_summary_total {
              display: flex;
              justify-content: space-between;
              padding: 10px;
              padding-bottom: 2rem;
              @media (max-width: 830px) {
                flex-direction: column;
              }
              .checkout_summary_total_left {
                @media (max-width: 830px) {
                  width: 100%;
                }
              }
              .checkout_summary_total_right {
                width: 50%;
                padding-top: 20px;
                @media (max-width: 830px) {
                  width: 100%;
                }
                h3 {
                  margin-bottom: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                }
                h1 {
                  font-size: 1.8rem;
                  position: relative;
                  display: inline-block;
                  margin: 0;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  span {
                    &:nth-child(2) {
                      position: relative;
                      &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: -3px;
                        width: 100%;
                        height: 2px;
                        background-color: black;
                      }
                      &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: -6px;
                        width: 100%;
                        height: 2px;
                        background-color: black;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .checkout_right {
          width: 40%;
          display: flex;
          flex-direction: column;
          gap: 10px;
          @media (max-width: 830px) {
            width: calc(100% - 10px);
          }
          .checkout_delivery_information {
            background-color: #fff;
            border-radius: 5px;
            width: 100%;
            flex: 1;
            padding: 5px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            h1 {
              margin: 10px 0 10px 20px;
              font-size: 1rem;
            }
            .chk_deliv_info_wrapper {
              padding: 10px 20px;
            }
          }
          .checkout_pay_now {
            background-color: #fff;
            border-radius: 5px;
            width: 100%;
            padding: 5px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            h1 {
              margin: 10px 20px 10px 35px;
              font-size: 1rem;
              text-align: center;
            }
            .payment_error {
              background-color: #ff4949;
              text-align: center;
              padding: 5px 20px;
              width: min-content;
              margin: 0 auto;
              border-radius: 5px;
              p {
                color: #fff;
                text-align: center;
                white-space: nowrap;
              }
            }
            .ck_pay_methods {
              padding-top: 20px;
              .crtchoosepaymentmethod {
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                height: 50px;
                .crtchooseOptions {
                  // margin-right: 20px;
                  padding: 5px 20px;
                  border-radius: 5px;
                  cursor: pointer;
                  .PaymentOnDelivery {
                    display: block;
                    height: 25px;
                    width: 40px;
                    fill: #282828;
                  }
                  .mpesaIc {
                    display: block;
                    height: 45px !important;
                    width: 45px !important;
                  }
                  .mastercardIc,
                  .visa {
                    width: 40px !important;
                  }
                }
                .activepaymentoptionm {
                  padding: 0px 20px;
                  border: 1px solid #5a8eff;
                  background-color: #e0cfff;
                  .mpesaIc {
                    display: block;
                    height: 35px !important;
                    width: 35px !important;
                  }
                }
                .activepaymentoption {
                  border: 1px solid #5a8eff;
                  background-color: #e0cfff;
                }
              }
            }
            .ck_pay_methods_playarea {
              .chk_mpesa_pay_option {
                padding: 10px 30px;
                margin-top: 20px;
                p {
                  text-align: center;
                }
                .chk_mpesa_pay_option_flexer {
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  margin-top: 1rem;
                  .mpesa_input_number_chk {
                    padding: 10px 10px;
                    margin-right: 20px;
                    border-radius: 5px;
                    outline: none;
                    border: 1px solid #000;
                    width: 300px;
                  }
                  .mpesa_pay_btn {
                    all: unset;
                    padding: 10px 30px;
                    background-color: #fff;
                    background: linear-gradient(
                      to bottom right,
                      #14a9ff,
                      #0088f0
                    ) !important;
                    border-radius: 43px;
                    text-align: center;
                    color: #fff;
                    cursor: pointer;
                    margin-right: 15px;
                    @media (max-width: 480px) {
                      margin-right: unset !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
