.mbsidebar {
  height: 100vh;
  width: 0;
  background-color: #fff;
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 999;
  overflow: hidden !important;
  transition: width 0.5s ease;
  .mbsidebar_container {
    position: relative;
    width: 100%;
    .mbisidebar_close {
      position: absolute;
      top: 10px;
      right: 20px;
      height: 25px;
      width: 25px;
    }
    .mobi_header_sidebar {
      margin: 10px 20px;
      .mbi_sdbar_left {
        .mbi_sdbar_logo {
          height: 40px;
          width: 40px;
        }
      }
    }
    .mbsidetop {
      .closemobisidebar {
        height: 30px;
        width: 30px;
      }
    }
    .mbnavlinks {
      .mbnavlink {
        margin: 20px;
        .mblinkwrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            font-size: 1.3rem;
            white-space: nowrap;
          }
          .mbsideangledown {
            height: 20px;
            width: 20px;
          }
          .mbsideangledown_rotate {
            transform: rotate(-90deg);
          }
        }
        .mblinkchildren {
          height: min-content;
          margin-left: 30px;
          transition: height 0.3s ease-in-out;
          .mblinknavchildren {
            .childmblink {
              margin: 10px 0;
              white-space: nowrap;
              a {
                p {
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }
  }
}
.showmbsidebar {
  width: 100%;
}
