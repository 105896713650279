.header {
  height: 70px;
  position: relative;
  background-color: #242424;
  transition: 0.3s;
  display: flex;
  align-items: center;
  @media (max-width: 830px) {
    height: 110px !important;
  }
  .hcontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 5%;
    width: 90%;
    @media (max-width: 830px) {
      flex-wrap: wrap;
    }
    .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 25%;
      @media (max-width: 830px) {
        order: 1;
        width: 50%;
      }
      .logoname {
        margin-right: 20px;
        color: #fff;
        font-family: 'Itim', cursive;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        white-space: nowrap;
        .mobibars,
        .mobixmark {
          height: 25px;
          height: 25px;
          fill: #fff;
          margin-right: 10px;
          cursor: pointer;
          @media (min-width: 830px) {
            display: none;
          }
        }
        .logonamelink {
          text-decoration: none;
          color: #fff;
          user-select: none;
          h2 {
            user-select: none;
            @media (max-width: 430px) {
              display: none;
            }
          }
          .nullchemy_logo_mobile {
            height: 25px;
            width: 25px;
            user-select: none;
            @media (min-width: 430px) {
              display: none;
            }
          }
        }
      }
      .filtercategories {
        @media (max-width: 830px) {
          display: none !important;
        }
      }
      .mobilesidebarnav {
        @media (min-width: 830px) {
          display: none !important;
        }
      }
    }
    .middle {
      width: 45%;
      display: flex;
      justify-content: flex-end;
      @media (max-width: 1439px) {
        width: 35%;
      }
      @media (max-width: 1250px) {
        width: 40%;
      }
      @media (max-width: 830px) {
        order: 3;
        width: 100%;
      }
    }
    .right {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      @media (max-width: 1439px) {
        width: 25%;
      }
      @media (max-width: 1250px) {
        width: 25%;
      }
      @media (max-width: 830px) {
        order: 2;
        width: 50%;
        justify-content: flex-end;
      }
      span {
        margin-right: 20px;
        font-size: 0.8rem;
        font-family: Roboto;
        cursor: pointer;
        color: #fff;
        white-space: nowrap;
        @media (max-width: 830px) {
          display: none;
        }
      }
      .login,
      .headercart {
        .accountdropdown {
          position: relative;
          .linktologinusr {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-right: 20px;
            text-decoration: none;
            .headeruser {
              height: 15px;
              width: 15px;
              fill: #fff;
            }
            span {
              margin: 0 5px;
              -webkit-user-select: none; /* Safari */
              -ms-user-select: none; /* IE 10 and IE 11 */
              user-select: none;
            }
            .angleusrdownaccount {
              height: 10px;
              width: 10px;
              fill: #fff;
              @media (max-width: 830px) {
                display: none;
              }
            }
          }
          .acctdropdown {
            background-color: #5b5b5b;
            position: absolute;
            z-index: 998;
            margin-top: 22px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            border-radius: 5px;
            &::before {
              content: '';
              width: 0;
              height: 0;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-bottom: 10px solid #5b5b5b;
              position: absolute;
              top: -10px;
              left: 30px;
              z-index: 900;
            }
            @media (max-width: 830px) {
              width: 100px;
              right: 0;
              &::before {
                left: unset;
                right: 15px;
              }
            }
            .linktologin {
              padding: 10px 15px;
              margin: 0 !important;
              &:hover {
                background-color: #757575;
              }
              P {
                color: #fff;
                margin-left: 5px;
                font-size: 0.9rem;
              }
              .usr_drop_ic {
                height: 15px;
                width: 15px;
              }
            }
          }
        }
        .linktologin,
        .linktocart {
          display: flex;
          align-items: center;
          cursor: pointer;
          margin-right: 20px;
          text-decoration: none;
          span {
            margin-left: 10px;
            font-family: Roboto;
            color: #fff;
            font-size: 0.8rem;
            @media (max-width: 830px) {
              display: none;
            }
          }
          .headeruser,
          .headcart {
            height: 25px;
            width: 25px;
            fill: #fff;
          }
        }
        .minicartdropdown {
          .linktocart {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-right: 20px;
            text-decoration: none;
            position: relative;
            .cartQuantityCounter {
              align-items: center;
              background-color: #cc1016;
              border-radius: 50%;
              color: #fff;
              display: flex;
              font-size: 10px;
              height: 5px;
              justify-content: center;
              margin-right: -5px;
              margin-top: -5px;
              padding: 6px;
              position: absolute;
              left: 5px;
              text-align: center;
              top: 0;
              width: 5px;
              z-index: 998;
            }
            span {
              margin-left: 10px;
              font-family: Roboto;
              color: #fff;
              font-size: 0.8rem;
              @media (max-width: 830px) {
                display: none;
              }
            }
            .headcart {
              height: 25px;
              width: 25px;
              fill: #fff;
            }
          }
        }
      }
    }
  }
}

.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  transition: 0.4s top cubic-bezier(0.3, 0.73, 0.3, 0.74);
  padding-top: 0px;
  padding-bottom: 0px;
}

@keyframes fadeInDown {
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
}
