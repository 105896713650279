:root {
  --rating-bg: #b8b8b8;
  --rating-front: #ff4209;
}
.ratings {
  margin-bottom: 20px;
  .ratings_container {
    max-width: 700px;
    .ratings_wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .ratings_left {
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          font-size: 3rem;
        }
        .ratings_no_of_reviews {
          font-size: 0.9rem;
        }
      }
      .ratings_right {
        flex: 1;
        margin-left: 1rem;
        .indicator_figure {
          width: 100%;
          .rating_indicator {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 20px;
            margin-bottom: 5px;
            span {
              font-size: 0.8rem;
            }
            .rating_indicator_bar {
              height: 10px;
              width: 100%;
              background-color: var(--rating-bg);
              border-radius: 5px;
              position: relative;
              overflow: hidden;
              &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                background-color: var(--rating-front);
                width: var(--progress-width);
                transition: 0.3s ease-in-out;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
              }
            }
          }
        }
      }
    }
  }
}

[data-star] {
  text-align: left;
  font-style: normal;
  display: inline-block;
  position: relative;
  unicode-bidi: bidi-override;
  font-size: 20px;
}

[data-star]::before {
  display: block;
  content: '★★★★★';
  color: var(--rating-bg);
}
[data-star]::after {
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  content: '★★★★★';
  width: 0;
  color: var(--rating-front);
  overflow: hidden;
  height: 100%;
}

[data-star^='0.1']::after {
  width: 2%;
}
[data-star^='0.2']::after {
  width: 4%;
}
[data-star^='0.3']::after {
  width: 6%;
}
[data-star^='0.4']::after {
  width: 8%;
}
[data-star^='0.5']::after {
  width: 10%;
}
[data-star^='0.6']::after {
  width: 12%;
}
[data-star^='0.7']::after {
  width: 14%;
}
[data-star^='0.8']::after {
  width: 16%;
}
[data-star^='0.9']::after {
  width: 18%;
}
[data-star^='1']::after {
  width: 20%;
}
[data-star^='1.1']::after {
  width: 22%;
}
[data-star^='1.2']::after {
  width: 24%;
}
[data-star^='1.3']::after {
  width: 26%;
}
[data-star^='1.4']::after {
  width: 28%;
}
[data-star^='1.5']::after {
  width: 30%;
}
[data-star^='1.6']::after {
  width: 32%;
}
[data-star^='1.7']::after {
  width: 34%;
}
[data-star^='1.8']::after {
  width: 36%;
}
[data-star^='1.9']::after {
  width: 38%;
}
[data-star^='2']::after {
  width: 40%;
}
[data-star^='2.1']::after {
  width: 42%;
}
[data-star^='2.2']::after {
  width: 44%;
}
[data-star^='2.3']::after {
  width: 46%;
}
[data-star^='2.4']::after {
  width: 48%;
}
[data-star^='2.5']::after {
  width: 50%;
}
[data-star^='2.6']::after {
  width: 52%;
}
[data-star^='2.7']::after {
  width: 54%;
}
[data-star^='2.8']::after {
  width: 56%;
}
[data-star^='2.9']::after {
  width: 58%;
}
[data-star^='3']::after {
  width: 60%;
}
[data-star^='3.1']::after {
  width: 62%;
}
[data-star^='3.2']::after {
  width: 64%;
}
[data-star^='3.3']::after {
  width: 66%;
}
[data-star^='3.4']::after {
  width: 68%;
}
[data-star^='3.5']::after {
  width: 70%;
}
[data-star^='3.6']::after {
  width: 72%;
}
[data-star^='3.7']::after {
  width: 74%;
}
[data-star^='3.8']::after {
  width: 76%;
}
[data-star^='3.9']::after {
  width: 78%;
}
[data-star^='4']::after {
  width: 80%;
}
[data-star^='4.1']::after {
  width: 82%;
}
[data-star^='4.2']::after {
  width: 84%;
}
[data-star^='4.3']::after {
  width: 86%;
}
[data-star^='4.4']::after {
  width: 88%;
}
[data-star^='4.5']::after {
  width: 90%;
}
[data-star^='4.6']::after {
  width: 92%;
}
[data-star^='4.7']::after {
  width: 94%;
}
[data-star^='4.8']::after {
  width: 96%;
}
[data-star^='4.9']::after {
  width: 98%;
}
[data-star^='5']::after {
  width: 100%;
}
