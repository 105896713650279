.sellwithus {
  min-height: calc(100vh - 70px);
  .sellwithuscontainer {
    margin: 0 10%;
    @media (max-width: 1400px) {
      margin: 0 5%;
    }
    .swulanding {
      .swubreadcrumb {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
        h1 {
          font-family: AEmb, Helvetica, Hiragino Kaku Gothic Pro, sans-serif;
          color: #232f3e;
          @media (max-width: 480px) {
            font-size: 1.6rem;
          }
        }
        .swusignupbtn {
          outline: none;
          border: none;
          background: linear-gradient(
            to bottom right,
            #14a9ff,
            #0088f0
          ) !important;
          border-radius: 23px;
          color: #fff !important;
          font-family: gibsonsemibold, Helvetica, Arial, sans-serif;
          font-size: 1.2rem;
          line-height: 1.4rem;
          padding: 10px 20px;
          text-align: center;
          transition: 0.4s;
          cursor: pointer;
          white-space: nowrap;
        }
      }
      .swulndingmain {
        width: 100%;
        margin: 10% 0;
        margin-bottom: 20%;
        display: flex;
        justify-content: space-evenly;
        @media (max-width: 1400px) {
          margin: 5% 0;
        }
        @media (max-width: 480px) {
          flex-direction: column-reverse;
        }
        .swulefttexts {
          flex: 1;
          @media (max-width: 1280px) {
            width: 100%;
            overflow: hidden;
          }
          h1 {
            font-size: 80px;
            line-height: 96px;
            font-weight: 900;
            font-family: AEmb, Helvetica, Hiragino Kaku Gothic Pro, sans-serif;
            color: #232f3e;
            @media (max-width: 1400px) {
              font-size: 70px;
              line-height: 79px;
            }
            @media (max-width: 480px) {
              font-size: 4rem;
              line-height: 3.5rem;
            }
          }
          p {
            font-size: 1.4rem;
            line-height: 30px;
            font-family: AEmb, Helvetica, Hiragino Kaku Gothic Pro, sans-serif;
            margin: 20px 0;
            @media (max-width: 1400px) {
              margin: 10px 0;
            }
          }
          .swusignupbtn {
            outline: none;
            border: none;
            background: linear-gradient(
              to bottom right,
              #14a9ff,
              #0088f0
            ) !important;
            border-radius: 35px;
            color: #fff !important;
            font-family: gibsonsemibold, Helvetica, Arial, sans-serif;
            font-size: 1.2rem;
            line-height: 1.4rem;
            padding: 20px 40px;
            text-align: center;
            transition: 0.4s;
            cursor: pointer;
            margin: 10px 0;
          }
          span {
            font-size: 0.8rem;
          }
        }
        .swurightimage {
          width: min-content;
          @media (max-width: 1280px) {
            display: none !important;
          }
          .swusvgic {
            margin-top: -30px;
            transform: rotate(-15deg);
            height: 80%;
          }
        }
      }
      .swubannerbtm {
        margin: 5%;
        @media (max-width: 480px) {
          margin: 0;
        }
        .swubannerwrapper {
          padding: 40px;
          background-color: #09090f;
          border-radius: 30px;
          @media (max-width: 480px) {
            padding: 20px;
          }
          h1 {
            font-size: 4rem;
            line-height: 5rem;
            font-weight: 900;
            font-family: AEmb, Helvetica, Hiragino Kaku Gothic Pro, sans-serif;
            color: #daebff;
            @media (max-width: 480px) {
              font-size: 2rem;
              line-height: 2rem;
            }
          }
          p {
            font-size: 1.4rem;
            line-height: 20px;
            font-family: AEmb, Helvetica, Hiragino Kaku Gothic Pro, sans-serif;
            margin: 20px 0;
            font-weight: 600;
            color: #daebff;
            @media (max-width: 480px) {
              font-size: 1rem;
              font-weight: 300;
              margin: 10px 0;
            }
          }
          .swusignupbtn {
            outline: none;
            border: none;
            background: linear-gradient(
              to bottom right,
              #14a9ff,
              #0088f0
            ) !important;
            border-radius: 35px;
            color: #fff !important;
            font-family: gibsonsemibold, Helvetica, Arial, sans-serif;
            font-size: 1.2rem;
            line-height: 1.4rem;
            padding: 20px 40px;
            text-align: center;
            transition: 0.4s;
            cursor: pointer;
            margin: 10px 0;
            @media (max-width: 480px) {
              padding: 10px 20px;
            }
          }
          span {
            font-size: 0.8rem;
            color: #daebff;
          }
        }
      }
    }
    .sellwithusregistershop,
    .sellwithusloginshop {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1000;
      background-color: #1b1b1b89;
      .regshopform {
        height: 100%;
        width: 100%;
        position: relative;
        .regformsh {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #fff;
          border-radius: 5px;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          width: 700px;
          pointer-events: all;
          .regformshwrapper {
            padding: 20px;
            height: calc(100% - 40px);
            width: calc(100% - 40px);
            position: relative;
            .xmarkicswu {
              position: absolute;
              top: 0;
              right: 0;
              height: 20px;
              width: 20px;
              padding: 10px;
              cursor: pointer;
            }
            h1 {
              font-size: 0.9rem;
            }
            .shreg_group_flexer {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 20px;
              width: 100%;
            }
            .shreg-group {
              margin: 10px 0;
              width: 100%;
              label {
                font-size: 0.8rem;
                span {
                  color: red;
                }
              }
              .shfrminput {
                margin-top: 5px;
                padding: 10px;
                outline: none;
                border-radius: 5px;
                width: calc(100% - 20px);
                border: 1px solid #000;
              }
              p {
                font-size: 0.6rem;
                color: #363636;
              }
            }
            .btnwrapswucont {
              display: flex;
              justify-content: flex-end;
              width: 100%;
              .regswubtn {
                outline: none;
                border: none;
                background: linear-gradient(
                  to bottom right,
                  #14a9ff,
                  #0088f0
                ) !important;
                border-radius: 35px;
                color: #fff !important;
                font-family: gibsonsemibold, Helvetica, Arial, sans-serif;
                font-size: 1.2rem;
                line-height: 1.4rem;
                padding: 15px 35px;
                text-align: center;
                transition: 0.4s;
                cursor: pointer;
                margin: 10px 0;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
